import React from 'react';

interface NotebookCoverProps {
  title: string;
  author: string | null;
  seed: string;
  logoUrl?: string;
}

const COLORS = [
  '#E6D5C3', // Kraft paper
  '#F3E7DB', // Light cream
  '#E5E0D8', // Warm gray
  '#DED7CC', // Natural
  '#E8DFD3', // Eggshell
  '#E2D9CF', // Parchment
  '#D8CFC6', // Taupe
  '#E4DBD2', // Sand
];

function generateColor(seed: string): string {
  const hash = seed.split('').reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  return COLORS[Math.abs(hash) % COLORS.length];
}

export function NotebookCover({ title, author, seed, logoUrl }: NotebookCoverProps) {
  const backgroundColor = generateColor(seed);
  const displayAuthor = author || 'Anonymous';
  
  return (
    <div className="relative aspect-[3/4] overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300">
      {/* Book cover with custom border radius */}
      <div 
        className="relative w-full h-full p-4 sm:p-6 flex flex-col"
        style={{ 
          backgroundColor,
          borderRadius: '2px 8px 8px 2px',
          backgroundImage: `
            radial-gradient(circle at center, rgba(255,255,255,0.03) 0%, rgba(255,255,255,0) 100%),
            url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noise'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.8' numOctaves='4' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100' height='100' filter='url(%23noise)' opacity='0.08'/%3E%3C/svg%3E")
          `,
          backgroundBlendMode: 'overlay',
          backgroundSize: '100% 100%, 150px 150px'
        }}
      >
        {/* Spine gradient overlay */}
        <div 
          className="absolute inset-0 pointer-events-none"
          style={{
            background: `linear-gradient(90deg, 
              rgba(0,0,0,0.15) 0%,
              rgba(0,0,0,0.08) 2%,
              rgba(0,0,0,0) 15%,
              rgba(0,0,0,0) 100%
            )`
          }}
        />

        <div className="flex-1 flex items-center justify-center mb-4 sm:mb-8 relative">
          {logoUrl ? (
            <img 
              src={logoUrl} 
              alt={`${title} logo`}
              className="w-12 h-12 sm:w-16 sm:h-16 object-cover rounded-lg"
            />
          ) : (
            <img 
              src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNCA0OEMzNy4yNTQ4IDQ4IDQ4IDM3LjI1NDggNDggMjRDNDggMTAuNzQ1MiAzNy4yNTQ4IDAgMjQgMEMxMC43NDUyIDAgMCAxMC43NDUyIDAgMjRDMCAzNy4yNTQ4IDEwLjc0NTIgNDggMjQgNDhaTTI0IDM2QzMwLjYyNzQgMzYgMzYgMzAuNjI3NCAzNiAyNEMzNiAxNy4zNzI2IDMwLjYyNzQgMTIgMjQgMTJDMTcuMzcyNiAxMiAxMiAxNy4zNzI2IDEyIDI0QzEyIDMwLjYyNzQgMTcuMzcyNiAzNiAyNCAzNlpNMjQgMzBDMjcuMzEzNyAzMCAzMCAyNy4zMTM3IDMwIDI0QzMwIDIwLjY4NjMgMjcuMzEzNyAxOCAyNCAxOEMyMC42ODYzIDE4IDE4IDIwLjY4NjMgMTggMjRDMTggMjcuMzEzNyAyMC42ODYzIDMwIDI0IDMwWiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg=="
              alt="Default logo"
              className="w-12 h-12 sm:w-16 sm:h-16"
            />
          )}
        </div>
        <div className="space-y-1 sm:space-y-2 relative">
          <h2 className="font-bricolage text-base sm:text-xl font-semibold leading-[1.1] line-clamp-3">{title}</h2>
          <p className="text-xs sm:text-sm opacity-75 line-clamp-1">{displayAuthor}</p>
        </div>
      </div>
    </div>
  );
}