import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeft, ChevronRight, Pencil, Trash2, X, Check, ImagePlus, GalleryVerticalEnd } from 'lucide-react';
import { DeleteConfirmation } from './DeleteConfirmation';
import { LinkPreviewService, LinkPreview as LinkPreviewType } from '../services/linkPreview';
import { LinkPreview } from './LinkPreview';
import { ImageOptimizer, OptimizedImage } from '../services/imageOptimizer';
import { ContentRenderer } from './ContentRenderer';
import { useMedia } from '../context/MediaContext';
import type { Post } from '../types';

interface PostListProps {
  posts: Post[];
  currentPage: number;
  onPageChange: (page: number) => void;
  isAuthenticated: boolean;
  userEmail: string | null;
  onEditPost: (post: Post) => void;
  onDeletePost: (postId: string) => void;
  isOwner: boolean;
  blogSlug: string;
}

export function PostList({ 
  posts, 
  currentPage, 
  onPageChange,
  isAuthenticated,
  userEmail,
  onEditPost,
  onDeletePost,
  isOwner,
  blogSlug
}: PostListProps) {
  const [editingPost, setEditingPost] = useState<Post | null>(null);
  const [imagePreviews, setImagePreviews] = useState<OptimizedImage[]>([]);
  const [linkPreviews, setLinkPreviews] = useState<LinkPreviewType[]>([]);
  const [deletePost, setDeletePost] = useState<Post | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const { mediaAtTop, setMediaPosition } = useMedia();
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);
  const titleRef = React.useRef<HTMLTextAreaElement>(null);

  const handleTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditingPost(prev => prev ? { ...prev, title: e.target.value } : null);
    if (titleRef.current) {
      titleRef.current.style.height = 'auto';
      titleRef.current.style.height = `${titleRef.current.scrollHeight}px`;
    }
  };

  const handleTitleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      textareaRef.current?.focus();
    }
  };

  const handleContentChange = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newContent = e.target.value;
    setEditingPost(prev => prev ? { ...prev, content: newContent } : null);

    if (newContent.endsWith(' ') || newContent.endsWith('\n')) {
      const urls = LinkPreviewService.extractUrls(newContent);
      if (urls.length > 0) {
        try {
          const newPreviews = await Promise.all(
            urls.map(url => LinkPreviewService.getPreview(url))
          );
          setLinkPreviews(prev => [...prev, ...newPreviews]);
        } catch (error) {
          console.error('Failed to generate link previews:', error);
        }
      }
    }

    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleEditStart = (post: Post) => {
    LinkPreviewService.clearProcessedUrls();

    const imageMatches = post.content.match(/!\[(.*?)\]\((.*?)\)/g) || [];
    const extractedImages = imageMatches.map(match => {
      const [_, name, url] = match.match(/!\[(.*?)\]\((.*?)\)/) || [];
      return {
        id: Date.now().toString() + Math.random(),
        url,
        name: name || 'image',
        size: 0
      };
    });

    const extractedPreviews = LinkPreviewService.extractPreviews(post.content);
    const cleanContent = post.content
      .replace(/!\[.*?\]\(.*?\)\n?/g, '')
      .replace(/<link-preview>.*?<\/link-preview>\n?/g, '')
      .trim();
    
    setImagePreviews(extractedImages);
    setLinkPreviews(extractedPreviews);
    setEditingPost({
      ...post,
      content: cleanContent
    });

    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
      if (titleRef.current) {
        titleRef.current.style.height = 'auto';
        titleRef.current.style.height = `${titleRef.current.scrollHeight}px`;
      }
    }, 0);
  };

  const handleEditSubmit = (post: Post) => {
    const imageMarkdown = imagePreviews.map(img => `\n![${img.name}](${img.url})\n`).join('');
    const linkPreviewMarkdown = linkPreviews.map(preview => LinkPreviewService.formatPreview(preview)).join('');
    
    const contentWithPreviews = mediaAtTop 
      ? [imageMarkdown, linkPreviewMarkdown, post.content].join('')
      : [post.content, imageMarkdown, linkPreviewMarkdown].join('');

    onEditPost({ ...post, content: contentWithPreviews });
    setEditingPost(null);
    setImagePreviews([]);
    setLinkPreviews([]);
    LinkPreviewService.clearProcessedUrls();
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || isUploading) return;

    setIsUploading(true);
    try {
      const optimizedImage = await ImageOptimizer.optimizeImage(file);
      setImagePreviews(prev => [...prev, optimizedImage]);
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setIsUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return (
    <>
      <main role="main" className="blog-posts">
        <div className="space-y-24">
          {posts.map((post) => (
            <article 
              key={post.id} 
              className="timeline-entry group"
              itemScope 
              itemType="http://schema.org/BlogPosting"
            >
              <header className="flex items-center justify-between gap-2 text-sm text-gray-500 mb-2">
                <time 
                  dateTime={new Date(post.date).toISOString()}
                  className="text-gray-400"
                >
                  {new Date(post.date).toLocaleDateString('en-US', {
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric'
                  })}
                </time>
                {isOwner && post.id !== 'welcome' && (
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => handleEditStart(post)}
                      className="p-1 hover:text-yellow-600 transition-colors"
                      title="Edit post"
                      aria-label="Edit post"
                    >
                      <Pencil className="w-4 h-4" />
                    </button>
                    <button
                      onClick={() => setDeletePost(post)}
                      className="p-1 hover:text-red-600 transition-colors"
                      title="Delete post"
                      aria-label="Delete post"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                )}
              </header>
              
              {editingPost?.id === post.id ? (
                <div className="space-y-4">
                  <textarea
                    ref={titleRef}
                    value={editingPost.title}
                    onChange={handleTitleChange}
                    onKeyDown={handleTitleKeyDown}
                    className="w-full text-2xl font-medium bg-transparent border-b border-gray-200 outline-none resize-none overflow-hidden"
                    rows={1}
                    style={{ minHeight: '2.5rem' }}
                  />
                  <textarea
                    ref={textareaRef}
                    value={editingPost.content}
                    onChange={handleContentChange}
                    className="w-full bg-transparent border-none outline-none resize-none h-24"
                    aria-label="Post content"
                    placeholder="Start writing..."
                  />

                  {(imagePreviews.length > 0 || linkPreviews.length > 0) && (
                    <div className="mt-4 space-y-4">
                      {imagePreviews.length > 0 && (
                        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                          {imagePreviews.map(img => (
                            <div key={img.id} className="relative group">
                              <img
                                src={img.url}
                                alt={img.name}
                                className="w-full h-32 object-cover rounded-lg"
                              />
                              <button
                                onClick={() => setImagePreviews(prev => prev.filter(i => i.id !== img.id))}
                                className="absolute top-2 right-2 p-1 bg-black/50 hover:bg-black/70 rounded-full text-white opacity-0 group-hover:opacity-100 transition-opacity"
                                title="Remove image"
                              >
                                <X className="w-4 h-4" />
                              </button>
                            </div>
                          ))}
                        </div>
                      )}

                      {linkPreviews.length > 0 && (
                        <div className="space-y-4">
                          {linkPreviews.map(preview => (
                            <LinkPreview
                              key={preview.id}
                              preview={preview}
                              onRemove={() => setLinkPreviews(prev => prev.filter(p => p.id !== preview.id))}
                              isEditing={true}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  )}

                  <div className="flex justify-between items-center">
                    <div className="flex items-center gap-2">
                      <input
                        ref={fileInputRef}
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        className="hidden"
                        id={`image-upload-${post.id}`}
                        disabled={isUploading}
                      />
                      <button
                        type="button"
                        onClick={() => fileInputRef.current?.click()}
                        className="flex items-center gap-2 px-4 py-2 rounded-full bg-gray-200 hover:bg-gray-300 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                        disabled={isUploading}
                      >
                        <ImagePlus className="w-4 h-4" />
                        <span className="hidden sm:inline">
                          {isUploading ? 'Uploading...' : 'Add Image'}
                        </span>
                      </button>
                      <button
                        type="button"
                        onClick={() => setMediaPosition(!mediaAtTop)}
                        className="p-2 rounded-full bg-gray-200 hover:bg-gray-300 transition-colors"
                        title={mediaAtTop ? "Media at top" : "Media at bottom"}
                      >
                        <GalleryVerticalEnd 
                          className={`w-4 h-4 transition-transform ${mediaAtTop ? 'rotate-180' : ''}`}
                        />
                      </button>
                    </div>
                    <div className="flex gap-2">
                      <button
                        onClick={() => {
                          setEditingPost(null);
                          setImagePreviews([]);
                          setLinkPreviews([]);
                          LinkPreviewService.clearProcessedUrls();
                        }}
                        className="p-2 text-gray-600 hover:text-gray-800"
                        title="Cancel"
                        aria-label="Cancel editing"
                      >
                        <X className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => handleEditSubmit(editingPost)}
                        className="px-8 py-2 rounded-full bg-yellow-400 hover:bg-yellow-500 transition-colors flex items-center justify-center"
                        title="Save changes"
                        aria-label="Save changes"
                      >
                        <Check className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <Link 
                    to={`/${blogSlug}/${post.id}`}
                    className="block group"
                  >
                    <h2 
                      className="text-2xl font-semibold mt-4 mb-4 group-hover:text-yellow-600 transition-colors"
                      itemProp="headline"
                    >
                      {post.title}
                    </h2>
                  </Link>
                  <div 
                    className="text-gray-700"
                    itemProp="articleBody"
                  >
                    <ContentRenderer 
                      content={post.content} 
                      isPreview={true} 
                      mediaAtTop={mediaAtTop}
                    />
                  </div>
                  <Link 
                    to={`/${blogSlug}/${post.id}`}
                    className="inline-block mt-4 text-yellow-600 hover:text-yellow-700 transition-colors"
                  >
                    Read more
                  </Link>
                </>
              )}
            </article>
          ))}
        </div>

        {posts.length > 20 && (
          <nav 
            className="flex justify-between items-center mt-8 pb-8"
            aria-label="Pagination"
          >
            {currentPage > 1 && (
              <button
                onClick={() => onPageChange(currentPage - 1)}
                className="flex items-center gap-2 text-gray-600"
                aria-label="Previous page"
              >
                <ChevronLeft className="w-4 h-4" /> Previous
              </button>
            )}
            {currentPage === 1 && <div />}
            {currentPage < Math.ceil(posts.length / 20) && (
              <button
                onClick={() => onPageChange(currentPage + 1)}
                className="flex items-center gap-2 text-gray-600 ml-auto"
                aria-label="Next page"
              >
                Next <ChevronRight className="w-4 h-4" />
              </button>
            )}
          </nav>
        )}
      </main>

      <DeleteConfirmation
        isOpen={deletePost !== null}
        onClose={() => setDeletePost(null)}
        onConfirm={() => {
          if (deletePost) {
            onDeletePost(deletePost.id);
            setDeletePost(null);
          }
        }}
        title={deletePost?.title || ''}
      />
    </>
  );
}