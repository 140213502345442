import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from './Auth';
import { BlogNamePrompt } from './BlogNamePrompt';
import { useAuth } from '../context/AuthContext';
import { AuthService } from '../services/auth';
import { Footer } from './Footer';
import { Navigation } from './Navigation';

const FEATURED_POSTS = [
  {
    title: "Writing isn't about filling a page; it's about clearing your mind.",
    content: "You don't write to be heard—you write to understand. Thoughts are often noisy until they're put into words. Once you write them down, they quiet down. It's the simplest way to organize what's in your head and see things clearly.",
    date: "Updated seconds ago"
  },
  {
    title: "A blank page is intimidating only when you think it has to be perfect.",
    content: "We think of the blank page as an invitation to spill your mind. No formatting, no distractions—just you and your thoughts. When you take the time to put your thinking into words, you turn chaos into clarity, one sentence at a time.",
    date: "Yesterday, 8:04 pm"
  },
  {
    title: "Writing is personal, not performative.",
    content: "The goal is simple: write to know yourself better. A blank page is like a mirror, showing you what you're thinking without filters or noise. It's not about writing for others or trying to sound smart. It's about finding out what you think and why it matters.",
    date: "31 Oct 2024, 12:03 am"
  }
];

export function Homepage() {
  const [showAuth, setShowAuth] = useState(false);
  const [showBlogNamePrompt, setShowBlogNamePrompt] = useState(false);
  const [authError, setAuthError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    if (!auth.isLoading && auth.isAuthenticated && auth.user) {
      navigate('/home', { replace: true });
    }
  }, [auth.isAuthenticated, auth.isLoading, auth.user, navigate]);

  const handleStartWriting = () => {
    if (auth.isAuthenticated) {
      navigate('/home', { replace: true });
    } else {
      setShowBlogNamePrompt(true);
    }
  };

  const handleBlogNameSubmit = async (name: string, email: string, password: string) => {
    setIsLoading(true);
    try {
      await AuthService.createUser(email, password, name);
      const success = await auth.login(email, password);
      if (success) {
        navigate('/home', { replace: true });
      }
    } catch (error) {
      if (error instanceof Error) {
        setAuthError(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignInSuccess = async (email: string, password: string) => {
    setIsLoading(true);
    try {
      const success = await auth.login(email, password);
      if (success) {
        navigate('/home', { replace: true });
      } else {
        setAuthError('Invalid email or password');
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (auth.isLoading) {
    return null;
  }

  return (
    <div className="min-h-screen bg-white flex flex-col">
      <div className="max-w-3xl mx-auto px-6 flex-1 w-full">
        <Navigation
          onStartWriting={handleStartWriting}
          onSignIn={() => setShowAuth(true)}
        />

        <header className="py-24 -mt-24 sm:py-24 sm:-mt-20">
          <div className="flex flex-col mt-8 sm:mt-12">
            <div className="pl-[3px]">
              <img 
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNCA0OEMzNy4yNTQ4IDQ4IDQ4IDM3LjI1NDggNDggMjRDNDggMTAuNzQ1MiAzNy4yNTQ4IDAgMjQgMEMxMC43NDUyIDAgMCAxMC43NDUyIDAgMjRDMCAzNy4yNTQ4IDEwLjc0NTIgNDggMjQgNDhaTTI0IDM2QzMwLjYyNzQgMzYgMzYgMzAuNjI3NCAzNiAyNEMzNiAxNy4zNzI2IDMwLjYyNzQgMTIgMjQgMTJDMTcuMzcyNiAxMiAxMiAxNy4zNzI2IDEyIDI0QzEyIDMwLjYyNzQgMTcuMzcyNiAzNiAyNCAzNlpNMjQgMzBDMjcuMzEzNyAzMCAzMCAyNy4zMTM3IDMwIDI0QzMwIDIwLjY4NjMgMjcuMzEzNyAxOCAyNCAxOEMyMC42ODYzIDE4IDE4IDIwLjY4NjMgMTggMjRDMTggMjcuMzEzNyAyMC42ODYzIDMwIDI0IDMwWiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg=="
                alt="Logo"
                className="w-12 h-12 mb-6"
              />
              <p className="text-[1.1rem] font-bricolage mb-8 lg:mb-4">Write, for yourself</p>
            </div>
            <h1 className="text-[8.6rem] md:text-[9rem] lg:text-[10.6rem] font-bold font-bricolage leading-[0.8] md:leading-[0.8] lg:leading-none -mt-8">
              <span className="block lg:inline">Note</span>
              <span className="block lg:inline">book</span>
            </h1>
          </div>
        </header>

        <main className="py-12">
          <div className="space-y-24">
            {FEATURED_POSTS.map((post, index) => (
              <article key={index} className="timeline-entry">
                <div className="timeline-date">{post.date}</div>
                <h2 className="text-2xl font-bold font-bricolage mt-4 mb-4">{post.title}</h2>
                <p className="text-gray-600 leading-relaxed">{post.content}</p>
              </article>
            ))}
          </div>
        </main>

        {showAuth && !auth.isAuthenticated && (
          <Auth
            onLogin={handleSignInSuccess}
            isLoading={isLoading}
            error={authError}
            onClose={() => {
              setShowAuth(false);
              setAuthError(null);
            }}
            onSignUp={() => {
              setShowAuth(false);
              setShowBlogNamePrompt(true);
            }}
          />
        )}

        {showBlogNamePrompt && (
          <BlogNamePrompt
            onSubmit={handleBlogNameSubmit}
            onClose={() => {
              setShowBlogNamePrompt(false);
              setAuthError(null);
            }}
            onSignIn={() => {
              setShowBlogNamePrompt(false);
              setShowAuth(true);
            }}
          />
        )}
      </div>
      <div className="max-w-3xl mx-auto px-6 w-full">
        <Footer 
          blogName="Made for curious people"
          footerLinks={[
            { text: "Explore Notebooks", href: "/explore" }
          ]}
        />
      </div>
    </div>
  );
}