import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { StorageService } from './storage';

export interface UsageMetrics {
  loginTimestamp: string | null;
  totalPosts: number;
  uniqueVisitors: number;
  storageUsed: {
    bytes: number;
    formatted: string;
  };
  lastPostDate: string | null;
  averagePostLength: number;
  imagesUploaded: number;
  totalViews: number;
}

export class AnalyticsService {
  private static formatBytes(bytes: number): string {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  static async getUserMetrics(userId: string): Promise<UsageMetrics> {
    try {
      // Get user document for login timestamp
      const userDoc = await getDoc(doc(db, 'users', userId));
      const userData = userDoc.data();
      
      // Get all posts
      const postsRef = collection(db, 'posts');
      const q = query(postsRef, where('userId', '==', userId));
      const querySnapshot = await getDocs(q);
      const posts = querySnapshot.docs.map(doc => doc.data());

      // Calculate metrics
      const totalPosts = posts.length;
      const lastPost = posts.sort((a, b) => 
        new Date(b.date).getTime() - new Date(a.date).getTime()
      )[0];

      // Calculate total content length and images
      let totalLength = 0;
      let imageCount = 0;
      posts.forEach(post => {
        totalLength += post.content.length;
        imageCount += (post.content.match(/!\[.*?\]\(.*?\)/g) || []).length;
      });

      // Get storage usage
      const storageBytes = StorageService.getUserStorageUsage(userData?.email || '');

      // In a real app, these would come from actual analytics
      // Here we're simulating with reasonable defaults
      const uniqueVisitors = Math.floor(totalPosts * 2.5);
      const totalViews = Math.floor(totalPosts * 5.8);

      return {
        loginTimestamp: userData?.lastLogin || null,
        totalPosts,
        uniqueVisitors,
        storageUsed: {
          bytes: storageBytes,
          formatted: this.formatBytes(storageBytes)
        },
        lastPostDate: lastPost?.date || null,
        averagePostLength: totalPosts ? Math.floor(totalLength / totalPosts) : 0,
        imagesUploaded: imageCount,
        totalViews
      };
    } catch (error) {
      console.error('Error fetching usage metrics:', error);
      throw new Error('Failed to fetch usage metrics');
    }
  }
}