import { nanoid } from 'nanoid';

export interface OptimizedImage {
  id: string;
  url: string;
  name: string;
  size: number;
}

export class ImageOptimizer {
  private static readonly MAX_WIDTH = 1200;
  private static readonly MAX_HEIGHT = 1200;
  private static readonly MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
  private static readonly JPEG_QUALITY = 0.8;
  private static readonly LOGO_MAX_SIZE = 60; // Max size for logos

  static async optimizeImage(file: File, maxWidth = this.MAX_WIDTH, maxHeight = this.MAX_HEIGHT): Promise<OptimizedImage> {
    if (!file.type.startsWith('image/')) {
      throw new Error('Invalid file type. Please upload an image.');
    }

    if (file.size > this.MAX_FILE_SIZE) {
      throw new Error('Image size should be less than 5MB');
    }

    return new Promise((resolve, reject) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        img.src = e.target?.result as string;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let { width, height } = img;

          // Calculate new dimensions while maintaining aspect ratio
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          if (!ctx) {
            reject(new Error('Failed to get canvas context'));
            return;
          }

          // Draw and optimize
          ctx.drawImage(img, 0, 0, width, height);
          
          // Use PNG for logos and JPEG for regular images
          const isLogo = maxWidth === this.LOGO_MAX_SIZE && maxHeight === this.LOGO_MAX_SIZE;
          const optimizedUrl = canvas.toDataURL(
            isLogo ? 'image/png' : 'image/jpeg',
            this.JPEG_QUALITY
          );

          resolve({
            id: nanoid(),
            url: optimizedUrl,
            name: file.name,
            size: Math.round(optimizedUrl.length * 0.75) // Approximate size in bytes
          });
        };

        img.onerror = () => reject(new Error('Failed to load image'));
      };

      reader.onerror = () => reject(new Error('Failed to read file'));
      reader.readAsDataURL(file);
    });
  }

  static async optimizeLogo(file: File): Promise<OptimizedImage> {
    return this.optimizeImage(file, this.LOGO_MAX_SIZE, this.LOGO_MAX_SIZE);
  }
}