import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { SettingsService } from '../services/settings';

interface MediaContextType {
  mediaAtTop: boolean;
  setMediaPosition: (position: boolean) => Promise<void>;
}

const MediaContext = createContext<MediaContextType | null>(null);

export function MediaProvider({ children }: { children: React.ReactNode }) {
  const { user } = useAuth();
  const [mediaAtTop, setMediaAtTop] = useState(true);

  useEffect(() => {
    if (user) {
      SettingsService.getUserSettings(user.uid).then(settings => {
        if (settings) {
          setMediaAtTop(settings.mediaAtTop);
        }
      });
    }
  }, [user]);

  const setMediaPosition = async (position: boolean) => {
    if (user) {
      await SettingsService.updateMediaPosition(user.uid, position);
      setMediaAtTop(position);
    }
  };

  return (
    <MediaContext.Provider value={{ mediaAtTop, setMediaPosition }}>
      {children}
    </MediaContext.Provider>
  );
}

export function useMedia() {
  const context = useContext(MediaContext);
  if (!context) {
    throw new Error('useMedia must be used within a MediaProvider');
  }
  return context;
}