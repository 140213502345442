import React from 'react';
import { Link } from 'react-router-dom';
import { Settings, BarChart2 } from 'lucide-react';

interface FooterLink {
  text: string;
  href: string;
}

interface FooterProps {
  blogName: string;
  authorName?: string | null;
  viewType?: 'feed' | 'index' | 'mag';
  onViewChange?: (view: 'feed' | 'index' | 'mag') => void;
  isOwner?: boolean;
  onOpenSettings?: () => void;
  onOpenUsage?: () => void;
  footerLinks?: FooterLink[];
  isLoggedOut?: boolean;
  onStartWriting?: () => void;
  onSignIn?: () => void;
}

export function Footer({ 
  blogName, 
  authorName, 
  viewType = 'feed', 
  onViewChange, 
  isOwner,
  onOpenSettings,
  onOpenUsage,
  footerLinks = [],
  isLoggedOut = false,
  onStartWriting,
  onSignIn
}: FooterProps) {
  const currentYear = new Date().getFullYear();
  
  if (isLoggedOut) {
    return (
      <footer className="py-8 mt-12 border-t border-gray-100">
        <div className="flex flex-col space-y-6 sm:space-y-0 sm:flex-row sm:items-center sm:justify-between">
          <div className="text-sm text-gray-400">
            © {currentYear} {blogName}
            {authorName && <span> · Written by {authorName}</span>}
          </div>
          <div className="flex items-center gap-6 text-sm">
            <Link to="/explore" className="text-gray-600 hover:text-gray-900 transition-colors">
              Explore
            </Link>
            <button
              onClick={onStartWriting}
              className="text-gray-600 hover:text-gray-900 transition-colors"
            >
              Create your notebook
            </button>
            <button
              onClick={onSignIn}
              className="text-gray-600 hover:text-gray-900 transition-colors"
            >
              Sign in
            </button>
          </div>
        </div>
      </footer>
    );
  }

  return (
    <footer className="py-8 mt-12 border-t border-gray-100">
      <div className="flex flex-col items-center gap-4">
        {isOwner && onViewChange && (
          <div className="w-full flex items-center justify-between mb-2">
            <div className="flex bg-gray-100 rounded-full p-1">
              <button
                onClick={() => onViewChange('feed')}
                className={`flex items-center gap-2 px-3 py-1 rounded-full text-sm transition-colors ${
                  viewType === 'feed' 
                    ? 'bg-white text-gray-900 shadow-sm' 
                    : 'text-gray-600 hover:text-gray-900'
                }`}
              >
                Feed
              </button>
              <button
                onClick={() => onViewChange('index')}
                className={`flex items-center gap-2 px-3 py-1 rounded-full text-sm transition-colors ${
                  viewType === 'index' 
                    ? 'bg-white text-gray-900 shadow-sm' 
                    : 'text-gray-600 hover:text-gray-900'
                }`}
              >
                Index
              </button>
              <button
                onClick={() => onViewChange('mag')}
                className={`flex items-center gap-2 px-3 py-1 rounded-full text-sm transition-colors ${
                  viewType === 'mag' 
                    ? 'bg-white text-gray-900 shadow-sm' 
                    : 'text-gray-600 hover:text-gray-900'
                }`}
              >
                Mag
              </button>
            </div>
            <div className="flex items-center gap-2">
              {onOpenUsage && (
                <button
                  onClick={onOpenUsage}
                  className="text-gray-500 hover:text-gray-700 p-2"
                  title="Usage"
                >
                  <BarChart2 className="w-4 h-4" />
                </button>
              )}
              {onOpenSettings && (
                <button
                  onClick={onOpenSettings}
                  className="text-gray-500 hover:text-gray-700 p-2"
                  title="Settings"
                >
                  <Settings className="w-4 h-4" />
                </button>
              )}
            </div>
          </div>
        )}
        <div className="text-sm text-gray-400">
          © {currentYear} {blogName}
          {authorName && <span> · Written by {authorName}</span>}
          {footerLinks.length > 0 && (
            <>
              {footerLinks.map((link, index) => (
                <React.Fragment key={link.href}>
                  <span> · </span>
                  <Link 
                    to={link.href}
                    className="text-yellow-600 hover:text-yellow-700 transition-colors"
                  >
                    {link.text}
                  </Link>
                </React.Fragment>
              ))}
            </>
          )}
        </div>
      </div>
    </footer>
  );
}