export class StorageService {
  private static readonly STORAGE_QUOTA = 50 * 1024 * 1024; // 50MB per user
  private static readonly USAGE_KEY = 'storage_usage';
  private static readonly POST_LIMIT = 100;
  private static readonly CONTENT_LENGTH_LIMIT = 50000; // 50K characters

  static getUserStorageUsage(email: string): number {
    const usage = localStorage.getItem(`${this.USAGE_KEY}_${email}`);
    return usage ? parseInt(usage, 10) : 0;
  }

  static updateStorageUsage(email: string, size: number): void {
    const currentUsage = this.getUserStorageUsage(email);
    const newUsage = currentUsage + size;
    
    if (newUsage > this.STORAGE_QUOTA) {
      throw new Error('Storage quota exceeded');
    }

    localStorage.setItem(`${this.USAGE_KEY}_${email}`, newUsage.toString());
  }

  static checkContentLength(content: string): boolean {
    return content.length <= this.CONTENT_LENGTH_LIMIT;
  }

  static checkPostLimit(email: string, currentCount: number): boolean {
    return currentCount < this.POST_LIMIT;
  }

  static getStorageQuota(): number {
    return this.STORAGE_QUOTA;
  }

  static getPostLimit(): number {
    return this.POST_LIMIT;
  }

  static getContentLengthLimit(): number {
    return this.CONTENT_LENGTH_LIMIT;
  }
}