import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

export class SettingsService {
  private static SETTINGS_KEY = 'blog_settings';
  private static PENDING_BLOG_NAME_KEY = 'pending_blog_name';

  static async getUserSettings(userId: string): Promise<{
    blogName: string;
    authorName: string | null;
    mediaAtTop: boolean;
  } | null> {
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (!userDoc.exists()) return null;
      
      const data = userDoc.data();
      return {
        blogName: data.blogName,
        authorName: data.authorName || null,
        mediaAtTop: data.mediaAtTop ?? true // Default to true if not set
      };
    } catch (error) {
      console.error('Error getting user settings:', error);
      return null;
    }
  }

  static async updateMediaPosition(userId: string, mediaAtTop: boolean): Promise<void> {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, { mediaAtTop });
    } catch (error) {
      console.error('Error updating media position:', error);
      throw error;
    }
  }

  static async saveUserSettings(userId: string, settings: {
    blogName: string;
    authorName: string | null;
    mediaAtTop?: boolean;
  }): Promise<void> {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        blogName: settings.blogName.trim(),
        authorName: settings.authorName?.trim() || null,
        ...(settings.mediaAtTop !== undefined && { mediaAtTop: settings.mediaAtTop })
      });
    } catch (error) {
      console.error('Error saving user settings:', error);
      throw error;
    }
  }

  static getPendingBlogName(): string | null {
    return localStorage.getItem(this.PENDING_BLOG_NAME_KEY);
  }

  static savePendingBlogName(name: string): void {
    localStorage.setItem(this.PENDING_BLOG_NAME_KEY, name);
  }

  static clearPendingBlogName(): void {
    localStorage.removeItem(this.PENDING_BLOG_NAME_KEY);
  }
}