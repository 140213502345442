import React from 'react';
import { ExternalLink, X, Image as ImageIcon } from 'lucide-react';
import type { LinkPreview as LinkPreviewType } from '../services/linkPreview';
import { YouTubeEmbed } from './YouTubeEmbed';

interface LinkPreviewProps {
  preview: LinkPreviewType;
  onRemove?: () => void;
  isEditing?: boolean;
}

export function LinkPreview({ preview, onRemove, isEditing = false }: LinkPreviewProps) {
  const hostname = new URL(preview.url).hostname;

  if (preview.type === 'youtube' && preview.videoId) {
    return (
      <div className="my-4 relative group">
        <YouTubeEmbed videoId={preview.videoId} title={preview.title} />
        {isEditing && onRemove && (
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onRemove();
            }}
            className="absolute top-2 right-2 p-2 bg-black/50 hover:bg-black/70 rounded-full text-white opacity-0 group-hover:opacity-100 transition-opacity"
            title="Remove video"
          >
            <X className="w-4 h-4" />
          </button>
        )}
      </div>
    );
  }

  return (
    <div className="my-4 border border-gray-200 rounded-lg overflow-hidden hover:border-gray-300 transition-colors group">
      <a 
        href={preview.url}
        target="_blank"
        rel="noopener noreferrer"
        className="block no-underline text-inherit"
      >
        {/* Image Section */}
        {preview.image ? (
          <div className="relative aspect-[1.91/1] bg-gray-100">
            <img 
              src={preview.image} 
              alt={preview.title}
              className="w-full h-full object-cover"
            />
          </div>
        ) : (
          <div className="aspect-[1.91/1] bg-gray-100 flex items-center justify-center">
            <ImageIcon className="w-12 h-12 text-gray-300" />
          </div>
        )}

        {/* Content Section */}
        <div className="p-4">
          <div className="flex items-start justify-between gap-4">
            <div className="flex-1 min-w-0">
              <h3 className="font-semibold text-lg text-gray-900 mb-2 line-clamp-2">
                {preview.title}
              </h3>
              <p className="text-sm text-gray-600 mb-3 line-clamp-2">
                {preview.description}
              </p>
              <div className="flex items-center gap-2 text-sm text-gray-500">
                {preview.favicon && (
                  <img 
                    src={preview.favicon} 
                    alt="" 
                    className="w-4 h-4 rounded-sm"
                  />
                )}
                <ExternalLink className="w-4 h-4" />
                <span className="truncate">{hostname}</span>
              </div>
            </div>
            {isEditing && onRemove && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onRemove();
                }}
                className="p-1 text-gray-400 hover:text-gray-600 opacity-0 group-hover:opacity-100 transition-opacity"
                title="Remove preview"
              >
                <X className="w-4 h-4" />
              </button>
            )}
          </div>
        </div>
      </a>
    </div>
  );
}