import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LogOut, User } from 'lucide-react';
import { useAuth } from '../context/AuthContext';

interface NavigationProps {
  onStartWriting: () => void;
  onSignIn: () => void;
}

export function Navigation({ onStartWriting, onSignIn }: NavigationProps) {
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth();

  const handleLogout = async () => {
    await logout();
    navigate('/', { replace: true });
  };

  return (
    <nav className="flex justify-end items-center py-8 gap-4 sm:gap-6">
      {isAuthenticated ? (
        <>
          <button
            onClick={onStartWriting}
            className="px-4 sm:px-6 py-2 rounded-full bg-yellow-400 hover:bg-yellow-500 transition-colors font-medium whitespace-nowrap"
          >
            <span className="hidden sm:inline">Go to Blog</span>
            <span className="sm:hidden">Create book</span>
          </button>
          <button
            onClick={handleLogout}
            className="flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors"
          >
            <LogOut className="w-4 h-4" />
            <span className="hidden sm:inline">Sign out</span>
          </button>
        </>
      ) : (
        <>
          <button
            onClick={onStartWriting}
            className="px-4 sm:px-6 py-2 rounded-full bg-yellow-400 hover:bg-yellow-500 transition-colors font-medium whitespace-nowrap"
          >
            <span className="hidden sm:inline">Start writing</span>
            <span className="sm:hidden">Create book</span>
          </button>
          <button
            onClick={onSignIn}
            className="flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors"
          >
            <User className="w-4 h-4 sm:hidden" />
            <span className="hidden sm:inline">Sign in</span>
          </button>
        </>
      )}
    </nav>
  );
}