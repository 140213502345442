import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged as firebaseAuthStateChanged,
  User as FirebaseUser,
  sendPasswordResetEmail,
  updateEmail,
  EmailAuthProvider,
  reauthenticateWithCredential,
  deleteUser,
  AuthError
} from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc, deleteDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { auth, db } from '../lib/firebase';
import { PostService } from './posts';
import { BlogService } from './blog';

export class AuthService {
  static async createUser(email: string, password: string, blogName: string): Promise<void> {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const { user } = userCredential;

      const blogSlug = await BlogService.getUniqueSlug(blogName);

      await setDoc(doc(db, 'users', user.uid), {
        email,
        blogName,
        blogSlug,
        createdAt: new Date().toISOString(),
        lastLogin: new Date().toISOString(),
        isPrivate: false
      });

      await PostService.addPost(user.uid, {
        title: "Welcome to your notebook",
        content: "Welcome! This is your space to write and share your thoughts. The editor above is ready for your first post.\n\nSome tips to get started:\n\n1. Hit the 'Post' button and just start typing\n2. Don't worry about making it perfect\n3. You can always edit or delete your posts later\n4. Your work is automatically saved as you type\n5. You can add images by using the Add image button\n\nWriting is thinking. Let your thoughts flow freely and see where they take you.\n\nHappy writing!",
        authorEmail: email,
        date: new Date().toISOString(),
        published: true
      });

    } catch (error) {
      if (error instanceof Error && 'code' in error) {
        const authError = error as AuthError;
        if (authError.code === 'auth/email-already-in-use') {
          throw new Error('This email is already registered');
        }
      }
      throw error;
    }
  }

  static async login(email: string, password: string): Promise<void> {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      
      const userRef = doc(db, 'users', userCredential.user.uid);
      await updateDoc(userRef, {
        lastLogin: new Date().toISOString()
      });
    } catch (error) {
      if (error instanceof Error && 'code' in error) {
        const authError = error as AuthError;
        if (authError.code === 'auth/invalid-credential') {
          throw new Error('Invalid email or password');
        } else if (authError.code === 'auth/user-not-found') {
          throw new Error('No account found with this email');
        } else if (authError.code === 'auth/wrong-password') {
          throw new Error('Incorrect password');
        }
      }
      throw new Error('Failed to sign in. Please try again.');
    }
  }

  static async logout(): Promise<void> {
    try {
      await signOut(auth);
    } catch (error) {
      throw new Error('Failed to log out');
    }
  }

  static async getBlogName(uid: string): Promise<string | null> {
    try {
      const userDoc = await getDoc(doc(db, 'users', uid));
      return userDoc.exists() ? userDoc.data().blogName : null;
    } catch (error) {
      console.error('Error getting blog name:', error);
      return null;
    }
  }

  static async sendPasswordResetEmail(email: string): Promise<void> {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      if (error instanceof Error && 'code' in error) {
        const authError = error as AuthError;
        if (authError.code === 'auth/user-not-found') {
          throw new Error('No account found with this email address');
        }
      }
      throw new Error('Failed to send password reset email');
    }
  }

  static async updateEmail(newEmail: string, password: string): Promise<void> {
    const user = auth.currentUser;
    if (!user || !user.email) {
      throw new Error('No authenticated user found');
    }

    try {
      const credential = EmailAuthProvider.credential(user.email, password);
      await reauthenticateWithCredential(user, credential);
      await updateEmail(user, newEmail);

      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, { email: newEmail });
    } catch (error) {
      if (error instanceof Error && 'code' in error) {
        const authError = error as AuthError;
        if (authError.code === 'auth/email-already-in-use') {
          throw new Error('This email is already in use');
        } else if (authError.code === 'auth/wrong-password') {
          throw new Error('Incorrect password');
        }
      }
      throw new Error('Failed to update email');
    }
  }

  static async deleteAccount(password: string): Promise<void> {
    const user = auth.currentUser;
    if (!user || !user.email) {
      throw new Error('No authenticated user found');
    }

    try {
      const credential = EmailAuthProvider.credential(user.email, password);
      await reauthenticateWithCredential(user, credential);

      const postsRef = collection(db, 'posts');
      const q = query(postsRef, where('userId', '==', user.uid));
      const querySnapshot = await getDocs(q);
      const deletePosts = querySnapshot.docs.map(doc => deleteDoc(doc.ref));
      await Promise.all(deletePosts);

      await deleteDoc(doc(db, 'users', user.uid));
      await deleteUser(user);
    } catch (error) {
      if (error instanceof Error && 'code' in error) {
        const authError = error as AuthError;
        if (authError.code === 'auth/wrong-password') {
          throw new Error('Incorrect password');
        }
      }
      throw new Error('Failed to delete account');
    }
  }

  static onAuthStateChanged(callback: (user: FirebaseUser | null) => void) {
    return firebaseAuthStateChanged(auth, callback);
  }
}