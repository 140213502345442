import { nanoid } from 'nanoid';

export interface LinkPreview {
  id: string;
  url: string;
  title: string;
  description: string;
  image: string | null;
  favicon: string | null;
  type: 'default' | 'youtube';
  videoId?: string;
}

export class LinkPreviewService {
  private static readonly URL_REGEX = /(?:https?:\/\/)?(?:(?:www|m)\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi;
  private static readonly YOUTUBE_REGEX = /(?:https?:\/\/)?(?:(?:www|m)\.)?(?:youtube\.com\/(?:watch\?(?:.*&)?v=|embed\/|v\/)|youtu\.be\/)([\w-]{11})(?:[^\w-]|$)/i;
  
  private static processedUrls = new Set<string>();
  private static previewCache = new Map<string, LinkPreview>();
  private static fetchPromises = new Map<string, Promise<LinkPreview>>();

  // Prioritize faster CORS proxies first
  private static readonly CORS_PROXIES = [
    'https://corsproxy.io/?',
    'https://api.allorigins.win/raw?url=',
    'https://api.codetabs.com/v1/proxy?quest='
  ];

  // Cache expiration time (24 hours)
  private static readonly CACHE_EXPIRATION = 24 * 60 * 60 * 1000;
  private static cacheTimestamps = new Map<string, number>();

  // Fetch timeout reduced to 3 seconds for faster feedback
  private static readonly FETCH_TIMEOUT = 3000;

  static extractUrls(text: string): string[] {
    if (!text?.trim() || text.includes('<link-preview>')) {
      return [];
    }

    // Extract URLs more aggressively - look for URLs in the last 2 words
    const words = text.trim().split(/\s+/);
    const lastTwoWords = words.slice(-2);
    
    const urls: string[] = [];
    for (const word of lastTwoWords) {
      const match = word.match(this.URL_REGEX);
      if (match) {
        const url = this.normalizeUrl(match[0]);
        if (!this.processedUrls.has(url)) {
          urls.push(url);
        }
      }
    }

    return urls;
  }

  private static normalizeUrl(url: string): string {
    try {
      const urlObj = new URL(url.startsWith('http') ? url : `https://${url}`);
      return urlObj.toString();
    } catch {
      return url;
    }
  }

  private static async fetchWithTimeout(url: string): Promise<Response> {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), this.FETCH_TIMEOUT);

    try {
      const response = await fetch(url, {
        signal: controller.signal,
        headers: {
          'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8',
          'Accept-Language': 'en-US,en;q=0.5',
          'Origin': window.location.origin
        }
      });
      return response;
    } finally {
      clearTimeout(timeoutId);
    }
  }

  private static async fetchWithFallback(url: string): Promise<string> {
    // Try all proxies in parallel for faster response
    const fetchPromises = this.CORS_PROXIES.map(proxy => 
      this.fetchWithTimeout(proxy + encodeURIComponent(url))
        .then(response => response.ok ? response.text() : Promise.reject())
        .catch(() => Promise.reject())
    );

    try {
      // Use Promise.race to get the first successful response
      const html = await Promise.race(fetchPromises);
      return html;
    } catch {
      // If all parallel requests fail, try sequentially with remaining proxies
      for (const proxy of this.CORS_PROXIES) {
        try {
          const response = await this.fetchWithTimeout(proxy + encodeURIComponent(url));
          if (response.ok) {
            return await response.text();
          }
        } catch {
          continue;
        }
      }
      throw new Error('All CORS proxies failed');
    }
  }

  private static extractYouTubeVideoId(url: string): string | null {
    const match = url.match(this.YOUTUBE_REGEX);
    return match ? match[1] : null;
  }

  static async getPreview(url: string): Promise<LinkPreview> {
    const normalizedUrl = this.normalizeUrl(url);

    // Check cache and expiration
    if (this.previewCache.has(normalizedUrl)) {
      const timestamp = this.cacheTimestamps.get(normalizedUrl) || 0;
      if (Date.now() - timestamp < this.CACHE_EXPIRATION) {
        return this.previewCache.get(normalizedUrl)!;
      } else {
        this.previewCache.delete(normalizedUrl);
        this.cacheTimestamps.delete(normalizedUrl);
      }
    }

    if (this.fetchPromises.has(normalizedUrl)) {
      return this.fetchPromises.get(normalizedUrl)!;
    }

    const previewPromise = (async () => {
      try {
        const videoId = this.extractYouTubeVideoId(normalizedUrl);
        const preview = videoId 
          ? await this.getYouTubePreview(normalizedUrl, videoId)
          : await this.getWebPreview(normalizedUrl);

        this.previewCache.set(normalizedUrl, preview);
        this.cacheTimestamps.set(normalizedUrl, Date.now());
        this.processedUrls.add(normalizedUrl);
        return preview;
      } catch (error) {
        const fallback = this.createFallbackPreview(normalizedUrl);
        this.previewCache.set(normalizedUrl, fallback);
        this.cacheTimestamps.set(normalizedUrl, Date.now());
        this.processedUrls.add(normalizedUrl);
        console.warn('Using fallback preview:', error);
        return fallback;
      } finally {
        this.fetchPromises.delete(normalizedUrl);
      }
    })();

    this.fetchPromises.set(normalizedUrl, previewPromise);
    return previewPromise;
  }

  private static async getYouTubePreview(url: string, videoId: string): Promise<LinkPreview> {
    try {
      const response = await this.fetchWithTimeout(
        `https://www.youtube.com/oembed?url=${encodeURIComponent(url)}&format=json`
      );
      
      if (!response.ok) {
        throw new Error('YouTube oEmbed failed');
      }

      const data = await response.json();
      
      return {
        id: nanoid(),
        url,
        title: data.title || 'YouTube Video',
        description: data.author_name ? `By ${data.author_name}` : '',
        image: `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`,
        favicon: 'https://www.youtube.com/favicon.ico',
        type: 'youtube',
        videoId
      };
    } catch {
      return {
        id: nanoid(),
        url,
        title: 'YouTube Video',
        description: '',
        image: `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`,
        favicon: 'https://www.youtube.com/favicon.ico',
        type: 'youtube',
        videoId
      };
    }
  }

  private static async getWebPreview(url: string): Promise<LinkPreview> {
    try {
      const html = await this.fetchWithFallback(url);
      const doc = new DOMParser().parseFromString(html, 'text/html');
      
      const getMetaContent = (selectors: string[]): string | null => {
        for (const selector of selectors) {
          const meta = doc.querySelector(selector);
          if (meta?.getAttribute('content')) {
            return meta.getAttribute('content');
          }
        }
        return null;
      };

      const urlObj = new URL(url);
      const hostname = urlObj.hostname;
      
      const title = getMetaContent([
        'meta[property="og:title"]',
        'meta[name="twitter:title"]',
        'meta[property="title"]',
        'meta[name="title"]'
      ]) || doc.title || hostname;

      const description = getMetaContent([
        'meta[property="og:description"]',
        'meta[name="twitter:description"]',
        'meta[property="description"]',
        'meta[name="description"]'
      ]) || '';

      const image = getMetaContent([
        'meta[property="og:image"]',
        'meta[name="twitter:image"]',
        'meta[property="image"]',
        'meta[name="image"]'
      ]);

      const favicon = getMetaContent(['link[rel="icon"]', 'link[rel="shortcut icon"]']) ||
        `https://www.google.com/s2/favicons?domain=${hostname}&sz=128`;

      return {
        id: nanoid(),
        url,
        title: title.trim(),
        description: description.trim(),
        image: image ? new URL(image, url).href : null,
        favicon: favicon ? new URL(favicon, url).href : `https://www.google.com/s2/favicons?domain=${hostname}&sz=128`,
        type: 'default'
      };
    } catch (error) {
      throw new Error('Failed to fetch preview');
    }
  }

  private static createFallbackPreview(url: string): LinkPreview {
    try {
      const urlObj = new URL(url);
      return {
        id: nanoid(),
        url,
        title: urlObj.hostname,
        description: url,
        image: null,
        favicon: `https://www.google.com/s2/favicons?domain=${urlObj.hostname}&sz=128`,
        type: 'default'
      };
    } catch {
      return {
        id: nanoid(),
        url,
        title: url,
        description: url,
        image: null,
        favicon: null,
        type: 'default'
      };
    }
  }

  static formatPreview(preview: LinkPreview): string {
    return `\n<link-preview>${JSON.stringify(preview)}</link-preview>\n`;
  }

  static extractPreviews(content: string): LinkPreview[] {
    const previewMatches = content.match(/<link-preview>(.*?)<\/link-preview>/g) || [];
    
    return previewMatches.map(match => {
      try {
        const json = match.replace(/<\/?link-preview>/g, '');
        const preview = JSON.parse(json) as LinkPreview;
        
        this.processedUrls.add(preview.url);
        this.previewCache.set(preview.url, preview);
        this.cacheTimestamps.set(preview.url, Date.now());
        
        return preview;
      } catch {
        return null;
      }
    }).filter((preview): preview is LinkPreview => preview !== null);
  }

  static clearProcessedUrls(): void {
    this.processedUrls.clear();
    this.previewCache.clear();
    this.cacheTimestamps.clear();
    this.fetchPromises.clear();
  }
}