import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Homepage } from './components/Homepage';
import { BlogPage } from './components/BlogPage';
import { PostPage } from './components/PostPage';
import { Dashboard } from './components/Dashboard';
import { ExploreNotebooks } from './components/ExploreNotebooks';
import { AdminDashboard } from './components/AdminDashboard';
import { ScrollToTop } from './components/ScrollToTop';
import { useAuth } from './context/AuthContext';
import { MediaProvider } from './context/MediaContext';

function App() {
  const { isAuthenticated, isLoading, user } = useAuth();

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-yellow-400"></div>
      </div>
    );
  }

  return (
    <>
      <ScrollToTop />
      <MediaProvider>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/explore" element={<ExploreNotebooks />} />
          <Route 
            path="/home" 
            element={isAuthenticated ? <Dashboard /> : <Navigate to="/" replace />} 
          />
          <Route 
            path="/alias/userland" 
            element={
              isAuthenticated && user?.email === 'gcmathure@gmail.com' 
                ? <AdminDashboard /> 
                : <Navigate to="/" replace />
            } 
          />
          <Route path="/:blogSlug" element={<BlogPage />} />
          <Route path="/:blogSlug/:postId" element={<PostPage />} />
        </Routes>
      </MediaProvider>
    </>
  );
}

export default App;