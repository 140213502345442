import React, { useState } from 'react';
import { Mail, Lock, X } from 'lucide-react';
import { AuthService } from '../services/auth';

interface AuthProps {
  onLogin: (email: string, password: string) => void;
  isLoading: boolean;
  error: string | null;
  onClose: () => void;
  onSignUp: () => void;
}

export function Auth({ onLogin, isLoading, error, onClose, onSignUp }: AuthProps) {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [localError, setLocalError] = useState<string | null>(null);
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [isSendingReset, setIsSendingReset] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLocalError(null);

    const { email, password } = formData;
    if (!email || !password) {
      setLocalError('Please fill in all fields');
      return;
    }

    try {
      const success = await AuthService.login(email, password);
      if (!success) {
        setLocalError('Invalid email or password');
        return;
      }

      onLogin(email, password);
      onClose();
    } catch (error: any) {
      if (error.code === 'auth/invalid-credential') {
        setLocalError('Invalid email or password');
      } else if (error.code === 'auth/user-not-found') {
        setLocalError('No account found with this email');
      } else if (error.code === 'auth/wrong-password') {
        setLocalError('Incorrect password');
      } else {
        setLocalError('Failed to sign in. Please try again.');
      }
    }
  };

  const handleForgotPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setLocalError(null);
    setIsSendingReset(true);

    try {
      await AuthService.sendPasswordResetEmail(formData.email);
      setResetEmailSent(true);
    } catch (error) {
      if (error instanceof Error) {
        setLocalError(error.message);
      }
    } finally {
      setIsSendingReset(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
        >
          <X className="w-5 h-5" />
        </button>

        <div className="flex items-center gap-3 mb-6">
          <div className="bg-yellow-100 p-2 rounded-full">
            <Mail className="w-6 h-6 text-yellow-600" />
          </div>
          <h2 className="text-xl font-semibold">
            {showForgotPassword ? 'Reset Password' : 'Sign in to your account'}
          </h2>
        </div>

        {showForgotPassword ? (
          <form onSubmit={handleForgotPassword} className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                Email address
              </label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-yellow-400 focus:border-transparent outline-none"
                placeholder="you@example.com"
                required
              />
            </div>

            {localError && (
              <p className="text-red-600 text-sm">{localError}</p>
            )}

            {resetEmailSent && (
              <p className="text-green-600 text-sm">
                Password reset email sent! Check your inbox for instructions.
              </p>
            )}

            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => setShowForgotPassword(false)}
                className="flex-1 bg-gray-100 hover:bg-gray-200 text-gray-700 py-2 px-4 rounded-lg font-medium transition-colors"
              >
                Back to Sign In
              </button>
              <button
                type="submit"
                disabled={isSendingReset}
                className="flex-1 bg-yellow-400 hover:bg-yellow-500 text-black py-2 px-4 rounded-lg font-medium transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isSendingReset ? 'Sending...' : 'Send Reset Link'}
              </button>
            </div>
          </form>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                Email address
              </label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-yellow-400 focus:border-transparent outline-none"
                placeholder="you@example.com"
                required
              />
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                Password
              </label>
              <input
                type="password"
                id="password"
                value={formData.password}
                onChange={(e) => setFormData(prev => ({ ...prev, password: e.target.value }))}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-yellow-400 focus:border-transparent outline-none"
                placeholder="Enter your password"
                required
              />
            </div>

            {(error || localError) && (
              <p className="text-red-600 text-sm">{error || localError}</p>
            )}

            <button
              type="button"
              onClick={() => setShowForgotPassword(true)}
              className="text-sm text-yellow-600 hover:text-yellow-700"
            >
              Forgot your password?
            </button>

            <div className="flex gap-3">
              <button
                type="button"
                onClick={onClose}
                className="flex-1 bg-gray-100 hover:bg-gray-200 text-gray-700 py-2 px-4 rounded-lg font-medium transition-colors"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isLoading}
                className="flex-1 bg-yellow-400 hover:bg-yellow-500 text-black py-2 px-4 rounded-lg font-medium transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isLoading ? 'Signing in...' : 'Sign in'}
              </button>
            </div>

            <div className="text-center text-sm text-gray-600">
              Don't have an account?{' '}
              <button
                type="button"
                onClick={onSignUp}
                className="text-yellow-600 hover:text-yellow-700 font-medium"
              >
                Sign up
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}