import React, { useState, useRef, useEffect } from 'react';
import { ImagePlus, X, Check, GalleryVerticalEnd } from 'lucide-react';
import { Auth } from './Auth';
import { ImageOptimizer, OptimizedImage } from '../services/imageOptimizer';
import { StorageService } from '../services/storage';
import { LinkPreviewService, LinkPreview as LinkPreviewType } from '../services/linkPreview';
import { LinkPreview } from './LinkPreview';
import { useMedia } from '../context/MediaContext';

interface WriterProps {
  isOpen: boolean;
  onPost: (title: string, content: string) => void;
  isAuthenticated: boolean;
  onAuthenticate: (email: string) => void;
  onCancel: () => void;
  userEmail?: string;
}

export function Writer({ isOpen, onPost, isAuthenticated, onAuthenticate, onCancel, userEmail }: WriterProps) {
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [showAuth, setShowAuth] = useState(false);
  const [imagePreviews, setImagePreviews] = useState<OptimizedImage[]>([]);
  const [linkPreviews, setLinkPreviews] = useState<LinkPreviewType[]>([]);
  const [pendingPost, setPendingPost] = useState<{ title: string; content: string } | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const { mediaAtTop, setMediaPosition } = useMedia();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const titleRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    LinkPreviewService.clearProcessedUrls();
    return () => {
      LinkPreviewService.clearProcessedUrls();
    };
  }, []);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [content]);

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.style.height = 'auto';
      titleRef.current.style.height = `${titleRef.current.scrollHeight}px`;
    }
  }, [title]);

  const handleContentChange = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newContent = e.target.value;
    setContent(newContent);

    if (newContent.endsWith(' ') || newContent.endsWith('\n')) {
      const urls = LinkPreviewService.extractUrls(newContent);
      if (urls.length > 0) {
        try {
          const newPreviews = await Promise.all(
            urls.map(url => LinkPreviewService.getPreview(url))
          );
          setLinkPreviews(prev => [...prev, ...newPreviews.filter(preview => 
            !prev.some(p => p.url === preview.url)
          )]);
        } catch (error) {
          console.error('Failed to generate link previews:', error);
        }
      }
    }
  };

  const handleTitleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      textareaRef.current?.focus();
    }
  };

  if (!isOpen) return null;

  const handlePost = () => {
    setError(null);

    if (!isAuthenticated) {
      setPendingPost({ title, content });
      setShowAuth(true);
      return;
    }

    try {
      if (!StorageService.checkContentLength(content)) {
        throw new Error(`Content exceeds maximum length of ${StorageService.getContentLengthLimit()} characters`);
      }

      const totalSize = content.length + imagePreviews.reduce((acc, img) => acc + img.size, 0);

      if (userEmail) {
        StorageService.updateStorageUsage(userEmail, totalSize);
      }

      const imageMarkdown = imagePreviews.map(img => `\n![${img.name}](${img.url})\n`).join('');
      const linkPreviewMarkdown = linkPreviews.map(preview => LinkPreviewService.formatPreview(preview)).join('');
      
      const contentWithPreviews = mediaAtTop ? 
        imageMarkdown + linkPreviewMarkdown + content :
        content + imageMarkdown + linkPreviewMarkdown;

      onPost(title, contentWithPreviews);
      setTitle('');
      setContent('');
      setImagePreviews([]);
      setLinkPreviews([]);
      LinkPreviewService.clearProcessedUrls();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to create post');
    }
  };

  const handleCancel = () => {
    setTitle('');
    setContent('');
    setImagePreviews([]);
    setLinkPreviews([]);
    setError(null);
    LinkPreviewService.clearProcessedUrls();
    onCancel();
  };

  const handleAuthSuccess = (email: string) => {
    onAuthenticate(email);
    setShowAuth(false);
    if (pendingPost) {
      const imageMarkdown = imagePreviews.map(img => `\n![${img.name}](${img.url})\n`).join('');
      const linkPreviewMarkdown = linkPreviews.map(preview => LinkPreviewService.formatPreview(preview)).join('');
      
      const contentWithPreviews = mediaAtTop ?
        imageMarkdown + linkPreviewMarkdown + pendingPost.content :
        pendingPost.content + imageMarkdown + linkPreviewMarkdown;
      
      onPost(pendingPost.title, contentWithPreviews);
      setPendingPost(null);
      setTitle('');
      setContent('');
      setImagePreviews([]);
      setLinkPreviews([]);
      LinkPreviewService.clearProcessedUrls();
    }
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || isUploading) return;

    try {
      setError(null);
      setIsUploading(true);
      const optimizedImage = await ImageOptimizer.optimizeImage(file);
      
      if (userEmail) {
        const currentUsage = StorageService.getUserStorageUsage(userEmail);
        const newUsage = currentUsage + optimizedImage.size;
        
        if (newUsage > StorageService.getStorageQuota()) {
          throw new Error('Storage quota exceeded. Please delete some images first.');
        }
      }

      setImagePreviews(prev => [...prev, optimizedImage]);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to upload image');
    } finally {
      setIsUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return (
    <>
      <div className="mb-12 bg-gray-50 rounded-lg p-6 relative">
        <button
          onClick={handleCancel}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
          title="Cancel"
        >
          <X className="w-5 h-5" />
        </button>

        <textarea
          ref={titleRef}
          placeholder="What's on your mind..."
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          onKeyDown={handleTitleKeyDown}
          className="w-full text-2xl font-medium bg-transparent border-none outline-none mb-2 resize-none overflow-hidden"
          rows={1}
          style={{ minHeight: '2.5rem' }}
        />
        <textarea
          ref={textareaRef}
          value={content}
          onChange={handleContentChange}
          className="w-full min-h-[4.5rem] bg-transparent border-none outline-none resize-none"
          placeholder="Start writing..."
        />
        
        {error && (
          <div className="mt-4 p-3 bg-red-50 text-red-600 rounded-lg">
            {error}
          </div>
        )}

        {(imagePreviews.length > 0 || linkPreviews.length > 0) && (
          <div className="mt-4 space-y-4">
            {imagePreviews.length > 0 && (
              <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                {imagePreviews.map(img => (
                  <div key={img.id} className="relative group">
                    <img
                      src={img.url}
                      alt={img.name}
                      className="w-full h-32 object-cover rounded-lg"
                    />
                    <button
                      onClick={() => setImagePreviews(prev => prev.filter(i => i.id !== img.id))}
                      className="absolute top-2 right-2 p-1 bg-black/50 hover:bg-black/70 rounded-full text-white opacity-0 group-hover:opacity-100 transition-opacity"
                      title="Remove image"
                    >
                      <X className="w-4 h-4" />
                    </button>
                  </div>
                ))}
              </div>
            )}

            {linkPreviews.length > 0 && (
              <div className="space-y-4">
                {linkPreviews.map(preview => (
                  <LinkPreview
                    key={preview.id}
                    preview={preview}
                    onRemove={() => setLinkPreviews(prev => prev.filter(p => p.id !== preview.id))}
                    isEditing={true}
                  />
                ))}
              </div>
            )}
          </div>
        )}

        <div className="flex justify-between items-center mt-4">
          <div className="flex items-center gap-2">
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="hidden"
              id="image-upload"
              disabled={isUploading}
            />
            <button 
              type="button"
              onClick={() => fileInputRef.current?.click()}
              className="flex items-center gap-2 px-4 py-2 rounded-full bg-gray-200 hover:bg-gray-300 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={isUploading}
            >
              <ImagePlus className="w-4 h-4" />
              <span className="hidden sm:inline">
                {isUploading ? 'Uploading...' : 'Add Image'}
              </span>
            </button>
            <button
              type="button"
              onClick={() => setMediaPosition(!mediaAtTop)}
              className="p-2 rounded-full bg-gray-200 hover:bg-gray-300 transition-colors"
              title={mediaAtTop ? "Media at top" : "Media at bottom"}
            >
              <GalleryVerticalEnd 
                className={`w-4 h-4 transition-transform ${mediaAtTop ? 'rotate-180' : ''}`}
              />
            </button>
          </div>
          <button
            type="button"
            onClick={handlePost}
            className="px-6 py-2 rounded-full bg-yellow-400 hover:bg-yellow-500 transition-colors"
          >
            Post
          </button>
        </div>
      </div>

      {showAuth && (
        <Auth
          onLogin={handleAuthSuccess}
          isLoading={false}
          error={null}
          onClose={() => {
            setShowAuth(false);
            setPendingPost(null);
          }}
          onSignUp={() => {}}
        />
      )}
    </>
  );
}