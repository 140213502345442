import React from 'react';
import { X } from 'lucide-react';
import { UsageMetrics } from '../services/analytics';

interface UsageModalProps {
  isOpen: boolean;
  onClose: () => void;
  metrics: UsageMetrics | null;
  isLoading: boolean;
}

export function UsageModal({ isOpen, onClose, metrics, isLoading }: UsageModalProps) {
  if (!isOpen) return null;

  const formatDate = (dateString: string | null) => {
    if (!dateString) return 'Never';
    return new Date(dateString).toLocaleDateString('en-US', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    });
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg w-full max-w-md relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
        >
          <X className="w-5 h-5" />
        </button>

        <div className="p-6">
          <h2 className="text-2xl font-bold mb-6">Usage</h2>

          {isLoading ? (
            <div className="flex justify-center py-4">
              <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-yellow-400"></div>
            </div>
          ) : !metrics ? (
            <div className="text-gray-500 text-center py-4">
              Unable to load usage metrics
            </div>
          ) : (
            <div className="space-y-2">
              <div className="flex justify-between py-2">
                <span className="text-gray-600">Last login</span>
                <span className="font-medium">{formatDate(metrics.loginTimestamp)}</span>
              </div>
              <div className="flex justify-between py-2">
                <span className="text-gray-600">Total posts</span>
                <span className="font-medium">{metrics.totalPosts}</span>
              </div>
              <div className="flex justify-between py-2">
                <span className="text-gray-600">Last post</span>
                <span className="font-medium">{formatDate(metrics.lastPostDate)}</span>
              </div>
              <div className="flex justify-between py-2">
                <span className="text-gray-600">Storage used</span>
                <span className="font-medium">{metrics.storageUsed.formatted}</span>
              </div>
              <div className="flex justify-between py-2">
                <span className="text-gray-600">Images uploaded</span>
                <span className="font-medium">{metrics.imagesUploaded}</span>
              </div>
              <div className="flex justify-between py-2">
                <span className="text-gray-600">Unique visitors</span>
                <span className="font-medium">{metrics.uniqueVisitors}</span>
              </div>
              <div className="flex justify-between py-2">
                <span className="text-gray-600">Total views</span>
                <span className="font-medium">{metrics.totalViews}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}