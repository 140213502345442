import React from 'react';
import { LinkPreview } from './LinkPreview';
import { LinkPreviewService } from '../services/linkPreview';

interface ContentRendererProps {
  content: string;
  isPreview?: boolean;
  mediaAtTop?: boolean;
}

export function ContentRenderer({ content, isPreview = false, mediaAtTop = true }: ContentRendererProps) {
  const linkPreviews = LinkPreviewService.extractPreviews(content);
  let cleanContent = content.replace(/<link-preview>.*?<\/link-preview>\n?/g, '');

  // URL regex that matches with or without protocol/www
  const urlRegex = /(?:https?:\/\/)?(?:(?:www|m)\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi;

  // Process content to separate images and text
  const processContent = () => {
    const parts = cleanContent.split(/(!?\[.*?\]\(.*?\))/g);
    const elements: JSX.Element[] = [];
    const images: JSX.Element[] = [];
    let textContent = '';

    parts.forEach((part, index) => {
      const imgMatch = part.match(/!\[(.*?)\]\((.*?)\)/);
      if (imgMatch) {
        const [, alt, src] = imgMatch;
        images.push(
          <img 
            key={`img-${index}`}
            src={src}
            alt={alt}
            className="max-w-full h-auto rounded-lg shadow-md"
            loading="lazy"
          />
        );
      } else if (part.trim()) {
        // Process text content for URLs
        const textParts = part.split(urlRegex);
        const matches = part.match(urlRegex) || [];
        let matchIndex = 0;

        textParts.forEach((textPart, i) => {
          if (textPart) {
            elements.push(<span key={`text-${index}-${i}`}>{textPart}</span>);
          }
          if (matchIndex < matches.length) {
            const url = matches[matchIndex];
            const fullUrl = url.startsWith('http') ? url : `https://${url}`;
            elements.push(
              <a
                key={`link-${index}-${i}`}
                href={fullUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-900 no-underline hover:bg-yellow-100 transition-colors rounded px-0.5 -mx-0.5"
              >
                {url}
              </a>
            );
            matchIndex++;
          }
        });

        textContent += part;
      }
    });

    return { elements, images, textContent };
  };

  const { elements, images } = processContent();

  const renderMedia = () => (
    <>
      {images.length > 0 && (
        <div className="flex flex-col gap-2.5 my-4">
          {isPreview ? images.slice(0, 1) : images}
        </div>
      )}
      {linkPreviews.length > 0 && (
        <div className="space-y-4">
          {(isPreview ? linkPreviews.slice(0, 1) : linkPreviews).map(preview => (
            <LinkPreview key={preview.id} preview={preview} />
          ))}
        </div>
      )}
    </>
  );

  return (
    <>
      {mediaAtTop && renderMedia()}
      <div className="whitespace-pre-wrap">
        {isPreview ? elements.slice(0, 100) : elements}
        {isPreview && elements.length > 100 && '...'}
      </div>
      {!mediaAtTop && renderMedia()}
    </>
  );
}