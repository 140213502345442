import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { LinkPreviewService } from '../services/linkPreview';

interface Post {
  id: string;
  title: string;
  date: string;
  content: string;
}

interface MagViewProps {
  posts: Post[];
  blogSlug: string;
  isOwner?: boolean;
}

function extractFirstImage(content: string): string | null {
  const match = content.match(/!\[(.*?)\]\((.*?)\)/);
  return match ? match[2] : null;
}

function extractPreview(content: string): string {
  const cleanContent = content
    .replace(/!\[.*?\]\(.*?\)/g, '')
    .replace(/<link-preview>.*?<\/link-preview>/g, '')
    .trim();
  return cleanContent.slice(0, 150) + (cleanContent.length > 150 ? '...' : '');
}

function shuffleArray<T>(array: T[]): T[] {
  const newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
}

export function MagView({ posts, blogSlug, isOwner = false }: MagViewProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 20;
  const totalPages = Math.ceil(posts.length / postsPerPage);
  const startIndex = (currentPage - 1) * postsPerPage;

  // Handle empty posts array
  if (!posts.length) {
    return (
      <div className="text-center py-12 text-gray-500">
        {isOwner ? "Write your first post to get started!" : "No posts available."}
      </div>
    );
  }

  // Keep first post as latest, shuffle the rest
  const [firstPost, ...restPosts] = posts;
  const shuffledPosts = useMemo(() => {
    // Filter posts with and without images
    const postsWithImages = restPosts.filter(post => extractFirstImage(post.content));
    const postsWithoutImages = restPosts.filter(post => !extractFirstImage(post.content));
    
    // Shuffle both arrays
    const shuffledWithImages = shuffleArray(postsWithImages);
    const shuffledWithoutImages = shuffleArray(postsWithoutImages);
    
    // Combine ensuring at least 50% have images
    const targetImageCount = Math.ceil(postsPerPage / 2);
    const selectedWithImages = shuffledWithImages.slice(0, targetImageCount);
    const selectedWithoutImages = shuffledWithoutImages.slice(0, postsPerPage - targetImageCount);
    
    return [firstPost, ...shuffleArray([...selectedWithImages, ...selectedWithoutImages])];
  }, [firstPost, restPosts]);

  const visiblePosts = shuffledPosts.slice(startIndex, startIndex + postsPerPage);

  // Calculate styled articles (25% of visible posts)
  const styledIndices = useMemo(() => {
    const numStyledArticles = Math.max(1, Math.floor((visiblePosts.length - 1) * 0.25));
    const availableIndices = Array.from({ length: visiblePosts.length - 1 }, (_, i) => i + 1);
    const shuffledIndices = shuffleArray(availableIndices);
    
    return {
      black: new Set(shuffledIndices.slice(0, Math.floor(numStyledArticles / 2))),
      yellow: new Set(shuffledIndices.slice(
        Math.floor(numStyledArticles / 2),
        numStyledArticles
      ))
    };
  }, [visiblePosts.length]);

  const remainingPosts = visiblePosts.slice(2);
  const totalRows = Math.ceil(remainingPosts.length / 4);

  return (
    <div className="max-w-7xl mx-auto border-l border-r border-gray-900">
      {/* First Row - Uneven Split */}
      {visiblePosts.length > 0 && (
        <div className="grid md:grid-cols-4 border-b border-gray-900">
          {/* Latest Post - Takes up 3 columns */}
          <article className="md:col-span-3 border-r border-gray-900">
            <Link 
              to={`/${blogSlug}/${visiblePosts[0].id}`}
              className="block group p-6"
            >
              {extractFirstImage(visiblePosts[0].content) && (
                <div className="overflow-hidden mb-6">
                  <img
                    src={extractFirstImage(visiblePosts[0].content)!}
                    alt=""
                    className="w-full object-cover group-hover:scale-105 transition-transform duration-500"
                  />
                </div>
              )}
              <time 
                className="text-sm text-gray-500 font-mono"
                dateTime={new Date(visiblePosts[0].date).toISOString()}
              >
                {new Date(visiblePosts[0].date).toLocaleDateString('en-US', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric'
                })}
              </time>
              <h2 className="font-bricolage text-3xl font-semibold mt-2 mb-4 group-hover:text-yellow-600 transition-colors">
                {visiblePosts[0].title}
              </h2>
              <p className="text-gray-600 leading-relaxed">
                {extractPreview(visiblePosts[0].content)}
              </p>
            </Link>
          </article>

          {/* Second Post - Takes up 1 column */}
          {visiblePosts.length > 1 && (
            <article className={`group ${
              styledIndices.black.has(1) ? 'bg-black' :
              styledIndices.yellow.has(1) ? 'bg-yellow-200' : ''
            }`}>
              <Link 
                to={`/${blogSlug}/${visiblePosts[1].id}`}
                className="block p-6 h-full"
              >
                {extractFirstImage(visiblePosts[1].content) && (
                  <div className="overflow-hidden mb-4">
                    <img
                      src={extractFirstImage(visiblePosts[1].content)!}
                      alt=""
                      className="w-full object-cover group-hover:scale-105 transition-transform duration-500"
                    />
                  </div>
                )}
                <time 
                  className={`text-sm font-mono ${
                    styledIndices.black.has(1) ? 'text-gray-400' : 'text-gray-500'
                  }`}
                  dateTime={new Date(visiblePosts[1].date).toISOString()}
                >
                  {new Date(visiblePosts[1].date).toLocaleDateString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                  })}
                </time>
                <h2 className={`font-bricolage text-xl font-semibold mt-2 ${
                  styledIndices.black.has(1) ? 
                    'text-white group-hover:text-yellow-400' : 
                    'group-hover:text-yellow-600'
                } transition-colors`}>
                  {visiblePosts[1].title}
                </h2>
                {!extractFirstImage(visiblePosts[1].content) && (
                  <p className={`mt-4 ${
                    styledIndices.black.has(1) ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {extractPreview(visiblePosts[1].content)}
                  </p>
                )}
              </Link>
            </article>
          )}
        </div>
      )}

      {/* Rest of the Grid Layout */}
      <div className="grid grid-cols-1 md:grid-cols-4">
        {Array.from({ length: totalRows }).map((_, rowIndex) => {
          const rowPosts = remainingPosts.slice(rowIndex * 4, (rowIndex * 4) + 4);
          const hasImages = rowPosts.some(post => extractFirstImage(post.content));
          
          // Last row special handling for vertical split
          const isLastRow = rowIndex === totalRows - 1;
          const shouldSplitVertically = isLastRow && rowPosts.length >= 2 && !hasImages;

          return (
            <React.Fragment key={rowIndex}>
              {shouldSplitVertically ? (
                // Vertical split for text-only last row
                rowPosts.slice(0, 2).map((post, index) => (
                  <article 
                    key={post.id}
                    className={`col-span-2 group ${
                      styledIndices.black.has(2 + rowIndex * 4 + index) ? 'bg-black' :
                      styledIndices.yellow.has(2 + rowIndex * 4 + index) ? 'bg-yellow-200' : ''
                    }`}
                  >
                    <Link 
                      to={`/${blogSlug}/${post.id}`}
                      className="block p-6"
                    >
                      <time 
                        className={`text-sm font-mono ${
                          styledIndices.black.has(2 + rowIndex * 4 + index) ? 'text-gray-400' : 'text-gray-500'
                        }`}
                        dateTime={new Date(post.date).toISOString()}
                      >
                        {new Date(post.date).toLocaleDateString('en-US', {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric'
                        })}
                      </time>
                      <h2 className={`font-bricolage text-xl font-semibold mt-2 ${
                        styledIndices.black.has(2 + rowIndex * 4 + index) ? 
                          'text-white group-hover:text-yellow-400' : 
                          'group-hover:text-yellow-600'
                      } transition-colors`}>
                        {post.title}
                      </h2>
                      <p className={`mt-4 ${
                        styledIndices.black.has(2 + rowIndex * 4 + index) ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {extractPreview(post.content)}
                      </p>
                    </Link>
                  </article>
                ))
              ) : (
                // Regular grid layout with borders
                rowPosts.map((post, index) => (
                  <article 
                    key={post.id}
                    className={`group border-t border-gray-900 ${
                      index < rowPosts.length - 1 ? 'border-r border-gray-900' : ''
                    } ${!isLastRow ? 'border-b border-gray-900' : ''} ${
                      styledIndices.black.has(2 + rowIndex * 4 + index) ? 'bg-black' :
                      styledIndices.yellow.has(2 + rowIndex * 4 + index) ? 'bg-yellow-200' : ''
                    }`}
                  >
                    <Link 
                      to={`/${blogSlug}/${post.id}`}
                      className="block p-6"
                    >
                      {extractFirstImage(post.content) && (
                        <div className="overflow-hidden mb-4">
                          <img
                            src={extractFirstImage(post.content)!}
                            alt=""
                            className="w-full object-cover group-hover:scale-105 transition-transform duration-500"
                          />
                        </div>
                      )}
                      <time 
                        className={`text-sm font-mono ${
                          styledIndices.black.has(2 + rowIndex * 4 + index) ? 'text-gray-400' : 'text-gray-500'
                        }`}
                        dateTime={new Date(post.date).toISOString()}
                      >
                        {new Date(post.date).toLocaleDateString('en-US', {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric'
                        })}
                      </time>
                      <h2 className={`font-bricolage text-xl font-semibold mt-2 ${
                        styledIndices.black.has(2 + rowIndex * 4 + index) ? 
                          'text-white group-hover:text-yellow-400' : 
                          'group-hover:text-yellow-600'
                      } transition-colors`}>
                        {post.title}
                      </h2>
                      {!extractFirstImage(post.content) && (
                        <p className={`mt-4 ${
                          styledIndices.black.has(2 + rowIndex * 4 + index) ? 'text-gray-300' : 'text-gray-600'
                        }`}>
                          {extractPreview(post.content)}
                        </p>
                      )}
                    </Link>
                  </article>
                ))
              )}
            </React.Fragment>
          );
        })}
      </div>

      {/* Pagination */}
      {posts.length > postsPerPage && totalPages > 1 && (
        <nav 
          className="flex justify-between items-center mt-8 pt-8 border-t border-gray-900"
          aria-label="Pagination"
        >
          {currentPage > 1 && (
            <button
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              className="flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors"
              aria-label="Previous page"
            >
              <ChevronLeft className="w-4 h-4" /> Previous
            </button>
          )}
          {currentPage === 1 && <div />}
          {currentPage < totalPages && (
            <button
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              className="flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors ml-auto"
              aria-label="Next page"
            >
              Next <ChevronRight className="w-4 h-4" />
            </button>
          )}
        </nav>
      )}
    </div>
  );
}