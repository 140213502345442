import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Footer } from './Footer';
import { MainNavigation } from './MainNavigation';
import { Auth } from './Auth';
import { BlogNamePrompt } from './BlogNamePrompt';
import { useAuth } from '../context/AuthContext';
import { AuthService } from '../services/auth';
import { PostService } from '../services/posts';
import { NotebookCover } from './NotebookCover';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface Notebook {
  id: string;
  name: string;
  slug: string;
  authorName?: string;
  logoUrl?: string;
  isPrivate?: boolean;
  postCount: number;
}

export function ExploreNotebooks() {
  const [notebooks, setNotebooks] = useState<Notebook[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showAuth, setShowAuth] = useState(false);
  const [showBlogNamePrompt, setShowBlogNamePrompt] = useState(false);
  const [authError, setAuthError] = useState<string | null>(null);
  const [isAuthLoading, setIsAuthLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const auth = useAuth();

  const NOTEBOOKS_PER_PAGE = 20;
  const MIN_POSTS_REQUIRED = 5;

  useEffect(() => {
    async function loadNotebooks() {
      try {
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('isPrivate', '!=', true));
        const snapshot = await getDocs(q);
        
        // Get post counts for all notebooks
        const notebooksWithCounts = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const postCount = await PostService.getPublishedPostCount(doc.id);
            return {
              id: doc.id,
              name: doc.data().blogName,
              slug: doc.data().blogSlug || doc.data().blogName,
              authorName: doc.data().authorName,
              logoUrl: doc.data().logoUrl,
              isPrivate: doc.data().isPrivate,
              postCount
            };
          })
        );

        // Filter notebooks with required post count
        const filteredNotebooks = notebooksWithCounts
          .filter(notebook => !notebook.isPrivate && notebook.postCount >= MIN_POSTS_REQUIRED)
          .sort((a, b) => b.postCount - a.postCount);

        setNotebooks(filteredNotebooks);
      } catch (error) {
        console.error('Error loading notebooks:', error);
      } finally {
        setIsLoading(false);
      }
    }

    loadNotebooks();
  }, []);

  const handleStartWriting = () => {
    if (auth.isAuthenticated) {
      navigate('/home', { replace: true });
    } else {
      setShowBlogNamePrompt(true);
    }
  };

  const handleBlogNameSubmit = async (name: string, email: string, password: string) => {
    setIsAuthLoading(true);
    try {
      await AuthService.createUser(email, password, name);
      const success = await auth.login(email, password);
      if (success) {
        navigate('/home', { replace: true });
      }
    } catch (error) {
      if (error instanceof Error) {
        setAuthError(error.message);
      }
    } finally {
      setIsAuthLoading(false);
    }
  };

  const handleSignInSuccess = async (email: string, password: string) => {
    setIsAuthLoading(true);
    try {
      const success = await auth.login(email, password);
      if (success) {
        navigate('/home', { replace: true });
      } else {
        setAuthError('Invalid email or password');
      }
    } finally {
      setIsAuthLoading(false);
    }
  };

  const totalPages = Math.ceil(notebooks.length / NOTEBOOKS_PER_PAGE);
  const startIndex = (currentPage - 1) * NOTEBOOKS_PER_PAGE;
  const visibleNotebooks = notebooks.slice(startIndex, startIndex + NOTEBOOKS_PER_PAGE);

  return (
    <div className="min-h-screen bg-white flex flex-col">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 flex-1 w-full">
        <MainNavigation
          onStartWriting={handleStartWriting}
          onSignIn={() => setShowAuth(true)}
        />

        <h1 className="text-center text-2xl mt-12 mb-12 font-bricolage">
          Explore notebooks created by<br />curious humans
        </h1>

        <main className="py-8 sm:py-12">
          {isLoading ? (
            <div className="flex justify-center items-center py-12">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-yellow-400"></div>
            </div>
          ) : notebooks.length > 0 ? (
            <>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 sm:gap-6">
                {visibleNotebooks.map(notebook => (
                  <Link
                    key={notebook.id}
                    to={`/${notebook.slug}`}
                    className="block transform hover:-translate-y-1 transition-all duration-300"
                  >
                    <NotebookCover
                      title={notebook.name}
                      author={notebook.authorName || null}
                      seed={notebook.id}
                      logoUrl={notebook.logoUrl}
                    />
                  </Link>
                ))}
              </div>

              {totalPages > 1 && (
                <div className="flex justify-between items-center mt-12">
                  <button
                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                    className="flex items-center gap-2 text-gray-600 hover:text-gray-900 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                  >
                    <ChevronLeft className="w-4 h-4" />
                    Previous
                  </button>
                  <span className="text-sm text-gray-600">
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                    disabled={currentPage === totalPages}
                    className="flex items-center gap-2 text-gray-600 hover:text-gray-900 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                  >
                    Next
                    <ChevronRight className="w-4 h-4" />
                  </button>
                </div>
              )}
            </>
          ) : (
            <div className="text-center py-12 text-gray-500">
              No notebooks available at the moment.
            </div>
          )}
        </main>

        {showAuth && !auth.isAuthenticated && (
          <Auth
            onLogin={handleSignInSuccess}
            isLoading={isAuthLoading}
            error={authError}
            onClose={() => {
              setShowAuth(false);
              setAuthError(null);
            }}
            onSignUp={() => {
              setShowAuth(false);
              setShowBlogNamePrompt(true);
            }}
          />
        )}

        {showBlogNamePrompt && (
          <BlogNamePrompt
            onSubmit={handleBlogNameSubmit}
            onClose={() => {
              setShowBlogNamePrompt(false);
              setAuthError(null);
            }}
          />
        )}
      </div>
      <div className="max-w-5xl mx-auto px-4 sm:px-6 w-full">
        <Footer blogName="Notebooks" />
      </div>
    </div>
  );
}