import { doc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface BlogData {
  userId: string;
  blogData: {
    email: string;
    blogName: string;
    blogSlug: string;
    authorName?: string;
    logoUrl?: string;
  };
}

export class BlogService {
  static slugify(name: string): string {
    return name
      .toLowerCase()
      .trim()
      .replace(/[^\w\s]/g, '')  // Remove special characters
      .replace(/\s+/g, '');     // Remove spaces without adding hyphens
  }

  static async getBlogBySlug(slug: string): Promise<BlogData | null> {
    try {
      const usersRef = collection(db, 'users');
      let querySnapshot;
      
      // Try exact slug match first
      let q = query(usersRef, where('blogSlug', '==', slug));
      querySnapshot = await getDocs(q);

      // If no match, try blog name (for backward compatibility)
      if (querySnapshot.empty) {
        q = query(usersRef, where('blogName', '==', slug));
        querySnapshot = await getDocs(q);
      }

      // If still no match, try slugified blog name
      if (querySnapshot.empty) {
        q = query(usersRef);
        querySnapshot = await getDocs(q);
        
        // Find a document where either the slug or slugified blogName matches
        const matchingDoc = querySnapshot.docs.find(doc => {
          const data = doc.data();
          return (
            this.slugify(data.blogName) === slug.toLowerCase() ||
            (data.blogSlug && data.blogSlug.toLowerCase() === slug.toLowerCase())
          );
        });

        if (matchingDoc) {
          return {
            userId: matchingDoc.id,
            blogData: matchingDoc.data() as BlogData['blogData']
          };
        }
      } else {
        const doc = querySnapshot.docs[0];
        return {
          userId: doc.id,
          blogData: doc.data() as BlogData['blogData']
        };
      }

      return null;
    } catch (error) {
      console.error('Error getting blog:', error);
      return null;
    }
  }

  static async getUniqueSlug(name: string): Promise<string> {
    const baseSlug = this.slugify(name);
    
    // Check if slug exists
    const blogsRef = collection(db, 'users');
    const q = query(blogsRef, where('blogSlug', '==', baseSlug));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return baseSlug;
    }

    // Find unique slug by adding number
    let counter = 1;
    
    while (true) {
      const newSlug = `${baseSlug}${counter}`;
      const q = query(blogsRef, where('blogSlug', '==', newSlug));
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        return newSlug;
      }
      
      counter++;
    }
  }

  static async updateBlogName(userId: string, newName: string): Promise<{ name: string; slug: string }> {
    const slug = await this.getUniqueSlug(newName);
    const userRef = doc(db, 'users', userId);
    
    await updateDoc(userRef, {
      blogName: newName.trim(),
      blogSlug: slug
    });

    return { name: newName.trim(), slug };
  }
}