import React from 'react';
import { Link } from 'react-router-dom';

interface HeaderProps {
  isWriterOpen: boolean;
  onPostClick: () => void;
  isAuthenticated: boolean;
  email: string | null;
  onSignInClick: () => void;
  onLogout: () => void;
  blogName: string;
  authorName: string | null;
  onOpenSettings: () => void;
  isOwner: boolean;
  logoUrl?: string;
  blogSlug?: string;
  hideAuth?: boolean;
}

export function Header({ 
  isWriterOpen, 
  onPostClick, 
  isAuthenticated, 
  email, 
  onSignInClick,
  onLogout,
  blogName,
  authorName,
  isOwner,
  logoUrl,
  blogSlug,
  hideAuth = false
}: HeaderProps) {
  const HeaderContent = () => (
    <>
      <div className="w-[52px] h-[52px] sm:w-[60px] sm:h-[60px] bg-transparent flex items-center justify-center">
        {logoUrl ? (
          <img 
            src={logoUrl} 
            alt={`${blogName} logo`}
            className="w-full h-full object-cover rounded-lg"
          />
        ) : (
          <img 
            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNCA0OEMzNy4yNTQ4IDQ4IDQ4IDM3LjI1NDggNDggMjRDNDggMTAuNzQ1MiAzNy4yNTQ4IDAgMjQgMEMxMC43NDUyIDAgMCAxMC43NDUyIDAgMjRDMCAzNy4yNTQ4IDEwLjc0NTIgNDggMjQgNDhaTTI0IDM2QzMwLjYyNzQgMzYgMzYgMzAuNjI3NCAzNiAyNEMzNiAxNy4zNzI2IDMwLjYyNzQgMTIgMjQgMTJDMTcuMzcyNiAxMiAxMiAxNy4zNzI2IDEyIDI0QzEyIDMwLjYyNzQgMTcuMzcyNiAzNiAyNCAzNlpNMjQgMzBDMjcuMzEzNyAzMCAzMCAyNy4zMTM3IDMwIDI0QzMwIDIwLjY4NjMgMjcuMzEzNyAxOCAyNCAxOEMyMC42ODYzIDE4IDE4IDIwLjY4NjMgMTggMjRDMTggMjcuMzEzNyAyMC42ODYzIDMwIDI0IDMwWiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg=="
            alt="Default logo"
            className="w-8 h-8 sm:w-10 sm:h-10"
          />
        )}
      </div>
      <div>
        <h1 className="text-xl font-bold hover:text-yellow-600 transition-colors">{blogName}</h1>
        {authorName && (
          <p className="text-sm text-gray-500">by {authorName}</p>
        )}
      </div>
    </>
  );

  return (
    <header className="flex items-center justify-between py-6">
      <div className="flex items-center gap-4">
        {blogSlug ? (
          <Link 
            to={`/${blogSlug}`} 
            className="flex items-center gap-4 hover:opacity-90 transition-opacity"
          >
            <HeaderContent />
          </Link>
        ) : (
          <div className="flex items-center gap-4">
            <HeaderContent />
          </div>
        )}
      </div>
      {isOwner ? (
        <div className="flex items-center gap-4">
          {!isWriterOpen && (
            <button
              onClick={onPostClick}
              className="px-6 py-2 rounded-full font-medium transition-all bg-yellow-400 hover:bg-yellow-500"
            >
              Write
            </button>
          )}
        </div>
      ) : !hideAuth && isAuthenticated ? (
        <button
          onClick={onLogout}
          className="text-gray-600 hover:text-gray-900 transition-colors"
        >
          Sign out
        </button>
      ) : null}
    </header>
  );
}