import React, { useState } from 'react';
import { X, AlertTriangle } from 'lucide-react';

interface BlogNamePromptProps {
  onSubmit: (name: string, email: string, password: string) => Promise<void>;
  onClose: () => void;
  onSignIn?: () => void;
}

export function BlogNamePrompt({ onSubmit, onClose, onSignIn }: BlogNamePromptProps) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showExistingAccountMessage, setShowExistingAccountMessage] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setShowExistingAccountMessage(false);
    let hasErrors = false;
    const newErrors = { name: '', email: '', password: '', confirmPassword: '' };
    
    if (!formData.name.trim()) {
      newErrors.name = 'Please enter a name for your notebook';
      hasErrors = true;
    } else if (formData.name.length > 30) {
      newErrors.name = 'Notebook name must be 30 characters or less';
      hasErrors = true;
    }

    if (!formData.email.trim()) {
      newErrors.email = 'Please enter your email';
      hasErrors = true;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
      hasErrors = true;
    }

    if (!formData.password) {
      newErrors.password = 'Please enter a password';
      hasErrors = true;
    } else if (formData.password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters';
      hasErrors = true;
    }

    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
      hasErrors = true;
    }

    setErrors(newErrors);
    
    if (!hasErrors) {
      try {
        await onSubmit(formData.name.trim(), formData.email.trim(), formData.password);
      } catch (error) {
        if (error instanceof Error && error.message.includes('email-already-in-use')) {
          setShowExistingAccountMessage(true);
        } else {
          setErrors(prev => ({ ...prev, email: error instanceof Error ? error.message : 'An error occurred' }));
        }
      }
    }
    setIsLoading(false);
  };

  if (showExistingAccountMessage) {
    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
        <div className="bg-white rounded-lg p-6 w-full max-w-md relative">
          <button
            type="button"
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
          >
            <X className="w-5 h-5" />
          </button>

          <div className="flex items-center gap-3 mb-6">
            <div className="bg-yellow-100 p-2 rounded-full">
              <AlertTriangle className="w-6 h-6 text-yellow-600" />
            </div>
            <h2 className="text-xl font-semibold">Account Already Exists</h2>
          </div>

          <p className="text-gray-600 mb-6">
            An account already exists with the email address <strong>{formData.email}</strong>. Would you like to sign in instead?
          </p>

          <div className="flex gap-3">
            <button
              type="button"
              onClick={onClose}
              className="flex-1 bg-gray-100 hover:bg-gray-200 text-gray-700 py-2 px-4 rounded-lg font-medium transition-colors"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => {
                onClose();
                onSignIn?.();
              }}
              className="flex-1 bg-yellow-400 hover:bg-yellow-500 text-black py-2 px-4 rounded-lg font-medium transition-colors"
            >
              Sign In
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md relative">
        <button
          type="button"
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
        >
          <X className="w-5 h-5" />
        </button>

        <h2 className="text-2xl font-bold mb-4">Create your notebook</h2>
        <p className="text-gray-600 mb-6">
          Choose a name that reflects your unique perspective and set up your account to get started.
        </p>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="blog-name" className="block text-sm font-medium text-gray-700 mb-1">
              Notebook Name
            </label>
            <input
              type="text"
              id="blog-name"
              value={formData.name}
              onChange={(e) => {
                if (e.target.value.length <= 30) {
                  setFormData(prev => ({ ...prev, name: e.target.value }));
                  setErrors(prev => ({ ...prev, name: '' }));
                }
              }}
              maxLength={30}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-yellow-400 focus:border-transparent outline-none"
              placeholder="Enter notebook name"
              autoFocus
            />
            <div className="flex justify-between items-center mt-1">
              <span className="text-sm text-red-600">{errors.name}</span>
              <span className="text-sm text-gray-500">{formData.name.length}/30</span>
            </div>
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={(e) => {
                setFormData(prev => ({ ...prev, email: e.target.value }));
                setErrors(prev => ({ ...prev, email: '' }));
              }}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-yellow-400 focus:border-transparent outline-none"
              placeholder="you@example.com"
            />
            {errors.email && (
              <span className="text-sm text-red-600 mt-1 block">{errors.email}</span>
            )}
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
              Password
            </label>
            <input
              type="password"
              id="password"
              value={formData.password}
              onChange={(e) => {
                setFormData(prev => ({ ...prev, password: e.target.value }));
                setErrors(prev => ({ ...prev, password: '' }));
              }}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-yellow-400 focus:border-transparent outline-none"
              placeholder="Create a password"
            />
            {errors.password && (
              <span className="text-sm text-red-600 mt-1 block">{errors.password}</span>
            )}
          </div>

          <div>
            <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-700 mb-1">
              Confirm Password
            </label>
            <input
              type="password"
              id="confirm-password"
              value={formData.confirmPassword}
              onChange={(e) => {
                setFormData(prev => ({ ...prev, confirmPassword: e.target.value }));
                setErrors(prev => ({ ...prev, confirmPassword: '' }));
              }}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-yellow-400 focus:border-transparent outline-none"
              placeholder="Confirm your password"
            />
            {errors.confirmPassword && (
              <span className="text-sm text-red-600 mt-1 block">{errors.confirmPassword}</span>
            )}
          </div>

          <div className="flex gap-3">
            <button
              type="button"
              onClick={onClose}
              className="flex-1 bg-gray-100 hover:bg-gray-200 text-gray-700 py-2 px-4 rounded-lg font-medium transition-colors"
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="flex-1 bg-yellow-400 hover:bg-yellow-500 text-black py-2 px-4 rounded-lg font-medium transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={isLoading}
            >
              {isLoading ? 'Creating...' : 'Create book'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}