import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Header } from './Header';
import { Footer } from './Footer';
import { PostList } from './PostList';
import { IndexView } from './IndexView';
import { MagView } from './MagView';
import { useAuth } from '../context/AuthContext';
import { PostService } from '../services/posts';
import { BlogService } from '../services/blog';

interface BlogState {
  posts: any[];
  currentPage: number;
  blogOwnerEmail: string | null;
  authorName: string | null;
  isLoading: boolean;
  ownerId: string | null;
  logo: string | null;
  blogName: string;
  notFound: boolean;
  viewType: 'feed' | 'index' | 'mag';
}

export function BlogPage() {
  const { blogSlug } = useParams();
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth();
  const [state, setState] = useState<BlogState>({
    posts: [],
    currentPage: 1,
    blogOwnerEmail: null,
    authorName: null,
    isLoading: true,
    ownerId: null,
    logo: null,
    blogName: '',
    notFound: false,
    viewType: 'feed'
  });

  useEffect(() => {
    let mounted = true;

    async function loadBlogData() {
      if (!blogSlug) {
        navigate('/', { replace: true });
        return;
      }

      try {
        const blog = await BlogService.getBlogBySlug(blogSlug);
        
        if (!mounted) return;

        if (!blog) {
          setState(prev => ({ ...prev, isLoading: false, notFound: true }));
          return;
        }

        if (isAuthenticated && user?.uid === blog.userId) {
          navigate('/home', { replace: true });
          return;
        }

        const posts = await PostService.getBlogPosts(blog.userId);

        if (!mounted) return;

        // Update document metadata
        document.title = `${blog.blogData.blogName} | Notebook`;
        
        // Update meta tags
        updateMetaTags({
          title: blog.blogData.blogName,
          description: `${blog.blogData.blogName} - A notebook by ${blog.blogData.authorName || 'Anonymous'}`,
          image: `https://notebk.co/api/og/${blogSlug}`,
          url: `https://notebk.co/${blogSlug}`
        });

        setState({
          posts: posts.filter(post => post.published),
          currentPage: 1,
          blogOwnerEmail: blog.blogData.email,
          authorName: blog.blogData.authorName || 'Anonymous',
          isLoading: false,
          ownerId: blog.userId,
          logo: blog.blogData.logoUrl || null,
          blogName: blog.blogData.blogName,
          notFound: false,
          viewType: blog.blogData.viewType || 'feed'
        });
      } catch (error) {
        console.error('Error loading blog:', error);
        if (mounted) {
          setState(prev => ({ ...prev, isLoading: false, notFound: true }));
        }
      }
    }

    loadBlogData();

    return () => {
      mounted = false;
      // Reset meta tags when unmounting
      updateMetaTags({
        title: 'Notebook - Capture your curiosity',
        description: 'A space for expression, introspection, capturing and sharing ideas',
        image: 'https://notebk.co/social-preview.png',
        url: 'https://notebk.co'
      });
    };
  }, [blogSlug, navigate, isAuthenticated, user]);

  // Helper function to update meta tags
  const updateMetaTags = ({ title, description, image, url }: { 
    title: string; 
    description: string; 
    image: string;
    url: string;
  }) => {
    // Basic meta tags
    document.querySelector('meta[name="title"]')?.setAttribute('content', title);
    document.querySelector('meta[name="description"]')?.setAttribute('content', description);

    // Open Graph
    document.querySelector('meta[property="og:title"]')?.setAttribute('content', title);
    document.querySelector('meta[property="og:description"]')?.setAttribute('content', description);
    document.querySelector('meta[property="og:image"]')?.setAttribute('content', image);
    document.querySelector('meta[property="og:url"]')?.setAttribute('content', url);

    // Twitter
    document.querySelector('meta[name="twitter:title"]')?.setAttribute('content', title);
    document.querySelector('meta[name="twitter:description"]')?.setAttribute('content', description);
    document.querySelector('meta[name="twitter:image"]')?.setAttribute('content', image);
    document.querySelector('meta[name="twitter:url"]')?.setAttribute('content', url);

    // Canonical URL
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.setAttribute('href', url);
    }
  };

  if (state.isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-yellow-400"></div>
      </div>
    );
  }

  if (state.notFound) {
    return (
      <div className="min-h-screen bg-white flex flex-col items-center justify-center px-6">
        <div className="max-w-md text-center">
          <h1 className="text-4xl font-bold mb-4">Blog Not Found</h1>
          <p className="text-gray-600 mb-8">
            We couldn't find a blog at <span className="font-mono bg-gray-100 px-2 py-1 rounded">{blogSlug}</span>. 
            The blog might have been moved or deleted.
          </p>
          <button
            onClick={() => navigate('/')}
            className="inline-flex items-center gap-2 px-6 py-2 bg-yellow-400 hover:bg-yellow-500 rounded-full transition-colors"
          >
            Return Home
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white flex flex-col">
      <div className={`mx-auto px-6 flex-1 w-full ${state.viewType === 'mag' ? 'max-w-7xl' : 'max-w-3xl'}`}>
        <Header
          isWriterOpen={false}
          onPostClick={() => {}}
          isAuthenticated={isAuthenticated}
          email={user?.email || null}
          onSignInClick={() => navigate('/')}
          onLogout={() => navigate('/')}
          blogName={state.blogName}
          authorName={state.authorName}
          onOpenSettings={() => {}}
          isOwner={false}
          logoUrl={state.logo}
          blogSlug={blogSlug}
          hideAuth={true}
        />

        <main className="py-12">
          {state.viewType === 'index' ? (
            <IndexView 
              posts={state.posts} 
              blogSlug={blogSlug!}
              isOwner={false}
            />
          ) : state.viewType === 'mag' ? (
            <MagView
              posts={state.posts}
              blogSlug={blogSlug!}
              isOwner={false}
            />
          ) : (
            <PostList
              posts={state.posts}
              currentPage={state.currentPage}
              onPageChange={(page) => setState(prev => ({ ...prev, currentPage: page }))}
              isAuthenticated={isAuthenticated}
              userEmail={user?.email}
              onEditPost={() => {}}
              onDeletePost={() => {}}
              isOwner={false}
              blogSlug={blogSlug!}
            />
          )}
        </main>
      </div>

      <div className={`mx-auto px-6 w-full ${state.viewType === 'mag' ? 'max-w-7xl' : 'max-w-3xl'}`}>
        <Footer 
          blogName={state.blogName}
          authorName={state.authorName}
          viewType={state.viewType}
          isOwner={false}
        />
      </div>
    </div>
  );
}