import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { ChevronLeft, ChevronRight, ExternalLink, Calendar, Clock, Eye, EyeOff } from 'lucide-react';
import { db } from '../lib/firebase';
import { useAuth } from '../context/AuthContext';

interface NotebookData {
  id: string;
  blogName: string;
  authorName: string | null;
  blogSlug: string;
  createdAt: string;
  lastLogin: string;
  email: string;
  isPrivate: boolean;
}

export function AdminDashboard() {
  const [notebooks, setNotebooks] = useState<NotebookData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { user } = useAuth();

  const ITEMS_PER_PAGE = 10;

  useEffect(() => {
    if (!user || user.email !== 'gcmathure@gmail.com') {
      navigate('/', { replace: true });
      return;
    }

    const fetchNotebooks = async () => {
      try {
        const usersRef = collection(db, 'users');
        const q = query(usersRef, orderBy('createdAt', 'desc'));
        const snapshot = await getDocs(q);
        
        const notebooksData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt || 'N/A',
          lastLogin: doc.data().lastLogin || 'N/A',
          authorName: doc.data().authorName || 'Anonymous',
          blogSlug: doc.data().blogSlug || doc.data().blogName,
          isPrivate: doc.data().isPrivate || false
        })) as NotebookData[];

        setNotebooks(notebooksData);
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching notebooks:', err);
        setError('Failed to load notebooks');
        setIsLoading(false);
      }
    };

    fetchNotebooks();
  }, [user, navigate]);

  const totalPages = Math.ceil(notebooks.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const visibleNotebooks = notebooks.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  const formatDate = (dateString: string) => {
    if (dateString === 'N/A') return 'N/A';
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-white flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-yellow-400"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-white flex items-center justify-center p-4">
        <div className="text-red-600 text-center">{error}</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#fafafa] p-4 sm:p-6">
      <div className="max-w-[1200px] mx-auto">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2 mb-6 sm:mb-8">
          <h1 className="text-xl sm:text-2xl font-bold text-gray-900">Notebooks Overview</h1>
          <span className="text-sm text-gray-500">
            Total notebooks: {notebooks.length}
          </span>
        </div>

        {/* Desktop Table View */}
        <div className="hidden md:block overflow-hidden rounded-lg border border-gray-200 bg-white shadow-sm">
          <div className="overflow-x-auto">
            <table className="w-full border-collapse">
              <thead>
                <tr className="border-b border-gray-200">
                  <th className="bg-gray-50/50 px-6 py-3 text-left">
                    <div className="text-sm font-medium text-gray-600">Notebook</div>
                  </th>
                  <th className="bg-gray-50/50 px-6 py-3 text-left">
                    <div className="text-sm font-medium text-gray-600">Author</div>
                  </th>
                  <th className="bg-gray-50/50 px-6 py-3 text-left">
                    <div className="text-sm font-medium text-gray-600">Email</div>
                  </th>
                  <th className="bg-gray-50/50 px-6 py-3 text-left">
                    <div className="text-sm font-medium text-gray-600">Created</div>
                  </th>
                  <th className="bg-gray-50/50 px-6 py-3 text-left">
                    <div className="text-sm font-medium text-gray-600">Last Login</div>
                  </th>
                  <th className="bg-gray-50/50 px-6 py-3 text-left">
                    <div className="text-sm font-medium text-gray-600">Visibility</div>
                  </th>
                  <th className="bg-gray-50/50 px-6 py-3 text-left">
                    <div className="text-sm font-medium text-gray-600">Actions</div>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {visibleNotebooks.map((notebook) => (
                  <tr 
                    key={notebook.id} 
                    className="group hover:bg-gray-50/50 transition-colors duration-150"
                  >
                    <td className="px-6 py-4 align-top">
                      <div className="font-medium text-gray-900">{notebook.blogName}</div>
                      <div className="text-sm text-gray-500">/{notebook.blogSlug}</div>
                    </td>
                    <td className="px-6 py-4 text-gray-600">{notebook.authorName}</td>
                    <td className="px-6 py-4 text-gray-600 font-mono text-sm">{notebook.email}</td>
                    <td className="px-6 py-4 text-gray-600 whitespace-nowrap">{formatDate(notebook.createdAt)}</td>
                    <td className="px-6 py-4 text-gray-600 whitespace-nowrap">{formatDate(notebook.lastLogin)}</td>
                    <td className="px-6 py-4">
                      <div className={`inline-flex items-center gap-1.5 px-2.5 py-1 rounded-full text-sm font-medium ${
                        notebook.isPrivate 
                          ? 'bg-gray-100 text-gray-700' 
                          : 'bg-emerald-50 text-emerald-700'
                      }`}>
                        {notebook.isPrivate ? (
                          <>
                            <EyeOff className="w-3.5 h-3.5" />
                            Hidden
                          </>
                        ) : (
                          <>
                            <Eye className="w-3.5 h-3.5" />
                            Visible
                          </>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <a
                        href={`/${notebook.blogSlug}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center gap-1 text-blue-600 hover:text-blue-700 font-medium"
                      >
                        View <ExternalLink className="w-4 h-4" />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Mobile Card View */}
        <div className="md:hidden space-y-4">
          {visibleNotebooks.map((notebook) => (
            <div 
              key={notebook.id}
              className="bg-white rounded-lg border border-gray-200 shadow-sm overflow-hidden"
            >
              <div className="p-4 space-y-4">
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="font-medium text-gray-900">{notebook.blogName}</h3>
                    <p className="text-sm text-gray-500 font-mono">/{notebook.blogSlug}</p>
                  </div>
                  <a
                    href={`/${notebook.blogSlug}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="shrink-0 inline-flex items-center gap-1 text-blue-600 hover:text-blue-700 font-medium"
                  >
                    View <ExternalLink className="w-4 h-4" />
                  </a>
                </div>

                <div className="space-y-3">
                  <div className="flex items-start gap-2">
                    <div className="w-5 h-5 mt-0.5 text-gray-400">
                      <Calendar className="w-full h-full" />
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-900">Created</p>
                      <p className="text-sm text-gray-600">{formatDate(notebook.createdAt)}</p>
                    </div>
                  </div>

                  <div className="flex items-start gap-2">
                    <div className="w-5 h-5 mt-0.5 text-gray-400">
                      <Clock className="w-full h-full" />
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-900">Last Login</p>
                      <p className="text-sm text-gray-600">{formatDate(notebook.lastLogin)}</p>
                    </div>
                  </div>

                  <div className="pt-3 border-t border-gray-100 space-y-2">
                    <p className="text-sm">
                      <span className="font-medium text-gray-900">Author:</span>{' '}
                      <span className="text-gray-600">{notebook.authorName}</span>
                    </p>
                    <p className="text-sm font-mono">
                      <span className="font-medium text-gray-900 font-sans">Email:</span>{' '}
                      <span className="text-gray-600">{notebook.email}</span>
                    </p>
                    <div className="pt-1">
                      <div className={`inline-flex items-center gap-1.5 px-2.5 py-1 rounded-full text-sm font-medium ${
                        notebook.isPrivate 
                          ? 'bg-gray-100 text-gray-700' 
                          : 'bg-emerald-50 text-emerald-700'
                      }`}>
                        {notebook.isPrivate ? (
                          <>
                            <EyeOff className="w-3.5 h-3.5" />
                            Hidden
                          </>
                        ) : (
                          <>
                            <Eye className="w-3.5 h-3.5" />
                            Visible
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {totalPages > 1 && (
          <div className="flex flex-col sm:flex-row justify-between items-center gap-4 mt-6">
            <button
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="w-full sm:w-auto flex items-center justify-center gap-2 px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-150"
            >
              <ChevronLeft className="w-4 h-4" /> Previous
            </button>
            <span className="text-sm text-gray-600">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
              className="w-full sm:w-auto flex items-center justify-center gap-2 px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-150"
            >
              Next <ChevronRight className="w-4 h-4" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}